.checkbox-card-container {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(4, 1fr);
    @include breakpoint-max(lg) {
        grid-template-columns: repeat(3, 1fr);
    }
    @include breakpoint-max(md) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint-max(sm) {
        grid-template-columns: 1fr;
    }
}

.checkbox-card {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid $color-grey-light;
    padding: 5px 10px;
    margin: 0;
    transition: background-color ease .15s; /* transition ace */
    .checkbox-card-image {
        flex-grow: 0;
        flex-shrink: 0;
        width: 40px;
    }
    .checkbox-card-info {
        flex-grow: 1;
        flex-shrink: 1;
        padding: 0 15px;
        overflow: hidden;
    }
    .checkbox-card-checkbox {
        flex-grow: 0;
        flex-shrink: 0;
        padding-top: 4px;
        width: 26px;
    }
    &:hover, &:focus, &:active, &:active:focus {
        background-color: $color-grey-lighter;
    }
}
