@use "sass:map";
@use "sass:list";

$valid-screen-sizes: xs, sm, md, lg, xl;

/* breakpoints tirés de la doc de bootstrap */
$screen-max: (
    xs: 510px, /* Mobile */
    sm: 767px, /* Tablets */
    md: 991px, /* Medium desktops */
    lg: 1199px, /* Large desktops */
    xl: 1499px /* XL desktops */
);

$screen-min: (
    xs: 511px, /* Mobile */
    sm: 768px, /* Tablets */
    md: 992px, /* Medium desktops */
    lg: 1200px, /* Large desktops */
    xl: 1500px /* XL desktops */
);

@mixin breakpoint-max($size, $screen-only: false) {
    @if not list.index($valid-screen-sizes, $size) {
        @error "#{$size} n'est pas un format valide pour le mixin breakpoint-max. Veuillez choisir parmis les formats suivants: #{$valid-screen-sizes}.";
    }

    @if $screen-only {
        @media only screen and (max-width: map.get($screen-max, $size)) {
            @content;
        }
    } @else {
        @media (max-width: map.get($screen-max, $size)) {
            @content;
        }
    }
}

@mixin breakpoint-min($size, $screen-only: false) {
    @if not list.index($valid-screen-sizes, $size) {
        @error "#{$size} n'est pas un format valide pour le mixin breakpoint-min. Veuillez choisir parmis les formats suivants: #{$valid-screen-sizes}.";
    }

    @if $screen-only {
        @media only screen and (min-width: map.get($screen-min, $size)) {
            @content;
        }
    } @else {
        @media (min-width: map.get($screen-min, $size)) {
            @content;
        }
    }
}

@mixin breakpoint-max-custom($size, $screen-only: false) {
    @if $screen-only {
        @media only screen and (max-width: $size) {
            @content;
        }
    } @else {
        @media (max-width: $size) {
            @content;
        }
    }
}

@mixin breakpoint-min-custom($size, $screen-only: false) {
    @if $screen-only {
        @media only screen and (min-width: $size) {
            @content;
        }
    } @else {
        @media (min-width: $size) {
            @content;
        }
    }
}
