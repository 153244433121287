.accordion-style1 {
    &.panel-group {
        margin-bottom: 0.5em;
        &.display-none {
            display: none;
        }
        .panel-heading {
            position: relative;
        }
        &.information-ecoforestiere {
            .panel-heading {
                .accordion-toggle {
                    background-color: #2a4137;
                    color: $color-white;
                    font-size: initial;
                }
                a {
                    &#collapse-information-ecoforestiere-accordeon {
                        background-color: #2a4137;
                        color: $color-white;
                        font-size: initial;
                    }
                }
            }
            .panel {
                border-color: #2a4137;
            }
        }
    }
}

/* style de l'accordéon pour mobile uniquement */
.accordion-style1-mobile-only {
    @include breakpoint-min(sm) {
        .panel-heading {
            display: none;
        }
        .panel, .panel-collapse, .panel-body {
            display: block !important;
            height: auto !important;
            padding: 0;
            border: none !important;
            background: none;
        }
    }
}

#headerTitre.information-ecoforestiere {
    color: #2a4137;
}

.accordion-fiches-sticky {
    .panel {
        position: relative;
    }
    .panel-heading {
        z-index: 1000;
        &.stick-top {
            position: fixed !important;
        }
        &.normal-position {
            position: absolute !important;
            top: 0 !important;
            left: -1px !important;
        }
        &.stick-bottom {
            position: absolute !important;
            top: initial !important;
            left: 0 !important;
            bottom: 0;
        }
    }
}

/* Accordion : Remplacement d'icône pour toute l'application */
.accordion-toggle .fa-angle-down:before {
    content: "\f13a";
}

/* Accordion : Remplacement d'icône pour toute l'application */
.accordion-toggle .fa-angle-right:before {
    content: "\f138";
}

.accordion-toggle-flex {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

.label-accordion {
    flex-shrink: 0;
    flex-grow: 0;
}

.accordion-fake {
    border: 1px solid $color-primary;
    margin-bottom: $spacing-sm;
    .accordion-fake-heading {
        line-height: 1;
        font-size: initial;
        letter-spacing: initial;
        text-transform: uppercase;
        font-weight: 700;
        color: $color-white;
        padding: 10px;
        background-color: $color-primary;
    }
    .accordion-fake-heading-lg {
        @extend .accordion-fake-heading;
        font-size: $font-size-title-md;
    }
    .accordion-fake-content {
        font-family: Calibri, sans-serif;
        padding: $spacing-sm;
        background-color: $color-grey-light-ace;
        border-top: 1px solid $color-grey-blue;
    }
}

.accordion-calcul-actif {
    border: 1px;
    border-color: rgb(135, 186, 33);
    padding-left: 6px;
    /* background: aquamarine; */
    border-style: solid;
    padding-right: 6px;
    padding-top: 5px;
    position: relative;
    margin-top: 35px;
    margin-bottom: 10px;
}

.accordion-calcul-actif-tag {
    width: 150px;
    padding: 3px;
    border: 1px;
    border-style: solid;
    border-color: rgb(135, 186, 33);
    position: absolute;
    top: -30px;
    background-color: rgb(135, 186, 33);
    color: $color-white;
    text-align: center;
    vertical-align: middle;
}

.accordion-actions {
    position: absolute;
    z-index: 999;
    right: 15px;
    top: 20%;
    border-color: $color-white;
    border-image: none;
    border-style: solid;
    border-width: 0 0 0 1px;
    min-width: 30px;
}

.accordion-actions-inspection {
    position: absolute;
    z-index: 999;
    right: 15px;
    border-color: $color-white;
    border-image: none;
    border-style: solid;
    border-width: 0 0 0 1px;
    min-width: 30px;
    max-width: 400px;
    padding-left: $spacing-sm;
    .form-group {
        margin-bottom: 0.5px;
    }
    input {
        background-color: $color-white;
        height: 31px;
    }
    @-moz-document url-prefix() {
        margin-top: 1.5px;
    }
}

.panel-default > .panel-heading .badge {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 2px;
}

.panel-group {
    margin-bottom: 0;
    margin-top: 2px;
}

.panel-collapse:not(.in) + .panel-bottom .tableflex-col .tableflex-head-mobile:not(.tableflex-head-mobile-noshow-foot) {
    display: block;
}

/* Positionnement de la liste à l'intérieur d'un accordéon */
.accordion-inside {
    margin-left: 1em;
}

#accordion-creation-adresse-proprietaire-proprietaires-lies {
    .div-table {
        .div-table-contenu {
            &.separateur {
                > .div-table-ligne {
                    &:not(:last-child) {
                        border-bottom: none;
                    }
                    &.regroupement {
                        border-bottom: 1px solid $color-black;
                    }
                }
            }
        }
        > .div-table-contenu {
            &:nth-child(even) {
                background-color: $color-white;
            }
        }
        > div.div-table-contenu {
            &:hover {
                background-color: $color-white;
            }
        }
    }
    input[type=checkbox] {
        &.ace {
            &.disabled, &:disabled, &[disabled] {
                .lbl::before {
                    /* prendre les couleurs de ace sur le checkbox quand il est disabled */
                    color: #32a3ce !important;
                }
            }
        }
    }
}

/* couleur dans titre d'accordéons de propagation selon type */
.propagation-conflit > .panel > .panel-heading .accordion-toggle {
    color: red !important;
}

.propagation-touche-gauche > .panel > .panel-heading .accordion-toggle {
    color: blueviolet !important;
}

.accordion-custom {
    .accordion-custom-link-arrow {
        transition: transform $time-sm ease;
        &.accordion-custom-link-arrow-open {
            transform: rotate(90deg);
        }
    }
}

/* bloc form - used in timelog page (mobile) */
.accordion-toggle-w-info {
    display: flex !important;
}

.accordion-toggle-w-info-arrow {
    flex-grow: 0;
    flex-shrink: 0;
}

.accordion-toggle-w-info-info {
    flex-shrink: 1;
    flex-grow: 1;
}
