.reperes-modal {
    display: none;
    &.donnees {
        background-color: #8AE65C !important;
    }
    &.active {
        display: block;
    }
}

#modal-reperes {
    font-size: $font-size-content-sm;
    .modal-header {
        padding-left: $spacing-sm;
        padding-right: $spacing-sm;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

#modal-zoom {
    .btn-select-fichiers-fiche {
        float: left;
        vertical-align: middle;
    }
    .modal-content {
        height: 98%;
    }
    .modal-zoom-ouvrir {
        border-style: none !important;
    }
}

.modal-zoom {
    width: 98%;
    height: 98%;
    padding: 0;
    top: 0;
    .modal-content {
        padding: 10px;
    }
}

.modal-zoom-ouvrir {
    &.fiche {
        border-color: $color-blue-action-principal !important;
        border: solid;
        border-width: thin;
        padding: $spacing-sm 5px 0 5px;
    }
}

.modal-xl {
    @include breakpoint-min(md) {
        width: 1300px;
        max-width: calc(100vw - 100px);
    }
}

.modal-xxl {
   @include breakpoint-min(md) {
        width: calc(100vw - 300px);
        max-width: 2100px
    }
}

.modal-reperes {
    @include breakpoint-min(md) {
        width: 99%;
        height: 99%;
    }
}

.modal-xxx {
    @include breakpoint-min(md) {
        width: calc(100vw - 100px);
        height: calc(100vh - 100px);
    }
}

.modal-full {
    width: calc(100vw - 30px);
    margin: 15px auto;
}

.modal-camera {
    @include breakpoint-min(md) {
        width: 85%;
        height: 85%;
    }
}

.modal-alerte-immense {
    @include breakpoint-min(md) {
        width: 90%;
    }
}

.modal-tg {
    @include breakpoint-min(md) {
        width: 75%;
        height: 75%;
    }
}

.modal-overlay {
    background-color: #676767bf;
}

.modal {
    z-index: 9010;
    &.fade {
        .modal-dialog {
            -webkit-transform: translate(0, 0); /* -25% réinitialisé à 0 */
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
            -webkit-transition: -webkit-transform 0.3s ease-out;
            -moz-transition: -moz-transform 0.3s ease-out;
            -o-transition: -o-transform 0.3s ease-out;
            transition: transform 0.3s ease-out;
        }
    }
}

.modal-header-small {
    padding: 10px;
    .modal-header-title {
        margin: 0;
    }
    .close {
        margin-top: -11px;
    }
}

.modal-message-ombre {
    -webkit-box-shadow: 1px 1px 5px 0 #555555;
    -moz-box-shadow: 1px 1px 5px 0 #555555;
    filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=1, OffY=1, Color='#555555', Positive='true');
    zoom: 1;
    box-shadow: 1px 1px 5px 0 #555555;
}

/* pour IE9 et + ce hack ci-dessous peut être fait avec les commentaires conditionnels d'IE :
[if gte IE 9]*/
@media only screen {
    .modal-message-ombre {
        filter: none;
    }
}

/*Ie9+*/
.modal-nm {
    width: 600px;
}

.modal-backdrop {
    background-color: #676767;
}

.modal-choisir {
    width: 225px;
    height: 55px;
}

#modal-message-alerte {
    position: absolute;
    z-index: 100000000000;
    background-color: $color-white;
}

#modal-message-alerte-footer {
    position: absolute;
    width: 100%;
    margin-left: -$spacing-sm;
    height: 100%;
    z-index: 2000;
    background-color: $color-white;
}

#modal-message-alerte-contenu {
    padding: 25px;
    margin-left: 5px;
    margin-right: 5px;
}

#modal-message-background {
    position: absolute;
    z-index: 2000;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.51);
}

#modal-step-contents {
    display: block;
}

#modalChoisirFiche {
    .in.tools.tools-top {
        -webkit-border-radius: 12px 12px 0 0;
        -moz-border-radius: 12px 12px 0 0;
        border-radius: 12px 12px 0 0;
        background-color: rgba(0, 0, 0, 0.75);
    }
}

/* modal sig specific styles */
.modal-responsive {
    .modal-dialog {
        max-width: calc(100% - 15px);
        width: 800px;
    }
}

.modal-sig-legend {
    .modal-dialog {
        width: 700px;
    }
}

.modal-sig-legend {
    img {
        max-width: 100%;
    }
}

.imageComparable {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.close-comparable-image {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #f1f1f1;
    font-size: 28px;
    font-weight: bold;
    &:hover, &:focus {
        color: #bbb;
        text-decoration: none;
        cursor: pointer;
    }
}

.est_inspecteur {
    background-color: $color-blue-action-principal;
}

.modal-body-scrollable {
    overflow: auto;
    height: calc(100vh - 180px);
    min-height: 250px;
}

.modal-over-modal {
    z-index: 9020;
    background-color: rgba(#676767, .5);
}


.modal-over-modal-2 {
    z-index: 9120;
    background-color: rgba(#676767, .5);
}
