@use 'sass:math';

.position-fixed {
    position: fixed;
}

.pos-abs-md-min {
    @include breakpoint-min(md) {
        position: absolute;
    }
}

.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex;
}

.align-items-initial {
    align-items: initial !important;
}

.align-items-flex-start {
    align-items: flex-start;
}

.align-items-flex-end {
    align-items: flex-end;
}

.align-items-center {
    align-items: center;
}

.align-items-center-md {
    @extend .align-items-center;
    @include breakpoint-max(md) {
        align-items: initial;
    }
}

.flex-direction-column {
    flex-direction: column;
}

.flex-direction-column-reverse-mobile {
    @include breakpoint-max(md) {
        flex-direction: column-reverse;
    }
}

.flex-direction-column-mobile {
    @include breakpoint-max(md) {
        flex-direction: column;
    }
}

.flex-direction-row-reverse-desktop {
    @include breakpoint-min(md) {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }
}

.justify-content-flex-end {
    justify-content: flex-end;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-space-between {
    justify-content: space-between !important;
}

.justify-content-space-evenly {
    justify-content: space-evenly !important;
}

.justify-content-space-around {
    justify-content: space-around !important;
}

.justify-content-flex-start-sm-max {
    @include breakpoint-max(sm) {
        justify-content: flex-start !important;
    }
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-grow-0 {
    flex-grow: 0;
}

.flex-shrink-1 {
    flex-shrink: 1;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-1 {
    flex: 1;
}

.flex-wrap-wrap {
    flex-wrap: wrap;
}

.flex-full-center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.rowflex-full-center-sm {
    @include breakpoint-min(sm) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.rowflex-space-between-md {
    @include breakpoint-min(md) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.nowrap {
    white-space: nowrap !important;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

.overflow-auto {
    overflow: auto;
}


.center,
.align-center {
    text-align: center !important;
}

/* custom rows for bloc forms -- do not use if your class override padding */
.row-padding-5 {
    margin-left: -2.5px;
    margin-right: -2.5px;
    .form-horizontal .form-group {
        margin-right: -2.5px;
        margin-left: -2.5px;
    }
    .row {
        margin-left: -2.5px;
        margin-right: -2.5px;
    }
    [class^='col-'], [class^='scol-'] {
        padding-left: 2.5px;
        padding-right: 2.5px;
    }
}

.form-horizontal .row-padding-5 .form-group {
    margin-right: -2.5px;
    margin-left: -2.5px;
}

.row-no-gutters {
    margin-left: 0;
    margin-right: 0;
    .form-horizontal .form-group {
        margin-right: 0;
        margin-left: 0;
    }
    > [class^='col-'] {
        padding-left: 0;
        padding-right: 0;
    }
}

.form-horizontal .row-no-gutters .form-group {
    margin-right: 0;
    margin-left: 0;
}

.page-break-avoid {
    page-break-inside: avoid;
}

/* quand on s'en sert, la colonne doit avoir un classe col-xs- */
.row-flex {
    display: flex;
    flex-wrap: wrap;
    flex-flow: nowrap;
}

@include breakpoint-min(sm) {
    .row-flex-sm {
        display: flex;
        flex-wrap: wrap;
        flex-flow: nowrap;
    }
}

/* Dans le cas d'un bouton avec un dropdown, on ne veut pas d'espace entre les deux éléments */
ul {
    .dropdown-menu {
        margin-top: -0.5em !important;
    }
}

.icon-centered {
    margin-right: 0 !important;
}

.bypass-bootrap-row-margin {
    margin-left: 0;
    margin-right: 12px;
}

div {
    &.content {
        position: absolute;
    }
}

.col-centered-65 {
    width: 65%;
    margin: 0 auto;
    text-align: left;
}

.col-fixed-150 {
    width: 150px;
}

.col-fixed-200 {
    width: 200px;
}

.col-fixed-250 {
    width: 250px;
}

.col-fixed-300 {
    width: 300px;
}

.col-fixed-800 {
    @include breakpoint-max(lg) {
        width: 802px;
    }
    @include breakpoint-max(md) {
        width: 600px;
    }
}

$type-cols: (
    "col": 12,
    "scol": 24
);

/* créé des classes pour col xl puisqu'elles n'existent pas dans bootstrap */
@each $col, $nbr_cols in $type-cols {
    @for $i from 0 through $nbr_cols {
        $pourcentage: calc(($i / $nbr_cols) * 100%);

        .#{$col}-xl-#{$i} {
            position: relative;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;
        }

        @include breakpoint-min(xl) {
            /* colonnes */
            @if $i > 0 {
                .#{$col}-xl-#{$i} {
                    float: left;
                    width: $pourcentage;
                }
            }

            /* colonnes offset */
            .#{$col}-xl-offset-#{$i} {
                margin-left: $pourcentage;
            }

            @if $pourcentage == 0% {
                $pourcentage: auto;
            }

            /* colonnes pull */
            .#{$col}-xl-pull-#{$i} {
                right: $pourcentage;
            }

            /* colonnes push */
            .#{$col}-xl-push-#{$i} {
                left: $pourcentage;
            }
        }
    }
}

/* présent dans les fiches, pour cacher du contenu */
.col-xs-0, .col-sm-0, .col-md-0, .col-lg-0, .col-xl-0 {
    display: none;
}

.cacher {
    display: none;
}

#contenu, .contenu {
    position: relative;
    margin-left: 5px;
    margin-right: 5px;
}

.hidden-xxs {
    @include breakpoint-max(xs) {
        display: none;
    }
}

.invisible {
    visibility: hidden;
}

.vraiment-invisible {
    display: none;
}

.margin-0, table.margin-0 {
    margin: 0 !important;
}

.margin-sm-0 {
    @include breakpoint-min(sm) {
        margin: 0 !important;
    }
}

.margin-bottom-0-last:last-child {
    margin-bottom: 0;
}

.margin-bottom-0-beforelast {
    &:nth-last-child(2), &:last-child {
        margin-bottom: 0 !important;
    }
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

.margin-0 {
    margin: 0 !important;
}

.margin-bottom-03 {
    margin-bottom: 0.3em !important;
}

.margin-bottom-05 {
    margin-bottom: 0.5em;
}

.margin-bottom-07 {
    margin-bottom: 0.7em;
}

.margin-bottom-1 {
    margin-bottom: 1em;
}

.margin-bottom-1-5 {
    margin-bottom: 1.5em;
}

.margin-bottom-1-5-desktop {
    @include breakpoint-max(md) {
        margin-bottom: 1.5em !important;
    }
}

.margin-bottom-2 {
    margin-bottom: 2em;
}

.margin-bottom-3 {
    margin-bottom: 3em;
}

.margin-bottom-1-mobile {
    @include breakpoint-max(md) {
        margin-bottom: 1em;
    }
}

.margin-left-0 {
    margin-left: 0 !important;
}

.margin-left-5 {
    margin-left: 5px !important;
}

.margin-left-05 {
    margin-left: 0.5em;
}

.margin-left-07 {
    margin-left: 0.7em;
}

.margin-left-right-05 {
    margin-left: 5px;
    margin-right: 5px;
}

.margin-left-right-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.margin-left-24 {
    margin-left: 24px;
}

.margin-right-0 {
    margin-right: 0 !important;
}

.margin-right-01 {
    margin-right: 0.1em;
}

.margin-right-03 {
    margin-right: 0.3em !important;
}

.margin-right-05 {
    margin-right: 0.5em;
}

.margin-right-1 {
    margin-right: 1em;
}

.margin-right-5px {
    margin-right: 5px !important;
}

.margin-right-15 {
    margin-right: 15px !important;
}

.margin-top-0 {
    margin-top: 0 !important;
}

.margin-top-03 {
    margin-top: 0.3em;
}

.margin-top-05 {
    margin-top: 0.5em;
}

.margin-top-1 {
    margin-top: 1em !important;
}

.margin-top-15 {
    margin-top: 1.5em;
}

.margin-top-2 {
    margin-top: 2em !important;
}

.margin-top-5px {
    margin-top: 5px;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.margin-12 {
    margin: 12px;
}

li.margin-bottom-2 {
    margin-bottom: 2em;
}

li.margin-bottom-3 {
    margin-bottom: 3em;
}

.ml-0 {
    margin-left: 0 !important;
}

.no-padding {
    padding: 0;
}

.padding-0 {
    padding: 0 !important;
}

.padding-5 {
    padding: 5px;
}

.padding-7 {
    padding: 7px;
}

.padding-8 {
    padding: 8px;
}

.padding-15 {
    padding: 15px;
}

.padding-20 {
    padding: 20px;
}

.padding-side-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.padding-top-0 {
    padding-top: 0 !important;
}

.padding-top-05 {
    padding-top: 0.5em;
}

.padding-top-1 {
    padding-top: 1em !important;
}

.padding-bottom-0 {
    padding-bottom: 0 !important;
}

.padding-bottom-2 {
    padding-bottom: 2em;
}

.padding-bottom-10 {
    padding-bottom: 10px !important;
}

.padding-10 {
    padding: 10px;
}

.padding-y-30-resp {
    padding-top: 30px;
    padding-bottom: 30px;
    @include breakpoint-max(sm) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-5px {
    padding-left: 5px !important;
}

.pl-15px {
    padding-left: 15px !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-1px {
    padding-right: 1px;
}

.pr-15px {
    padding-right: 15px !important;
}

.pr-30px {
    padding-right: 30px;
}

.height-auto {
    height: auto !important;
}

.height-100 {
    height: 100% !important;
}

.height-90px {
    height: 90px;
}

.min-width-100 {
    min-width: 100%;
}

.max-width-100 {
    max-width: 100%;
}

.vertical-align-middle {
    vertical-align: middle;
}

img.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 1px;
    border: 1px solid $color-black;
}

.pre-wrap {
    white-space: pre-wrap;
}

.obj-fit-cover {
    object-fit: cover;
}

@for $i from 1 to 11 {
    .z-index-#{$i} {
        z-index: $i !important;
    }
}

.left1 {
    float: left;
    width: 50%;
    height: 100%;
    color: $color-black;
    font-size: 1.0em;
    margin-bottom: 2px;
}

.right1 {
    float: right;
    width: 50%;
    height: 100%;
    color: $color-black;
    font-weight: bold;
    font-size: 1.0em;
    margin-bottom: 2px;
}

.lineContainer1 {
    clear: both;
}

.space {
    height: 55px;
}

.page-100vh {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    .page-100vh-header,
    .page-100vh-footer {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .page-100vh-content {
        flex-grow: 1;
        flex-shrink: 0;
    }
}
