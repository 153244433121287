$font-family-content: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-calibri: Calibri, sans-serif;
$font-family-arial: Arial, sans-serif;

$base-line-height: 1.5;
$base-font-size: 14px;

$font-size-title-xl: 25px;
$font-size-title-lg: 23px;
$font-size-title-md: 18px;
$font-size-title-sm: 15px;

$font-size-content-lg: 16px;
$font-size-content-md: $base-font-size;
$font-size-content-sm: 12px;