@use "sass:math";

/* fonctions utiles pour compiler le scss */
@function ends-with($string, $find) {
    @if (str-index($string, $find) == (str-length($string) - str-length($find) + 1)) {
        @return true;
    } @else {
        @return false;
    }
}

@mixin print-mode {
    @media print {
        @content;
    }
}

/* ****
    Ajoute les styles normalement et ajoute le media query pour l'impression.
    Mets les styles à important dans le mode impression pour écraser les styles print de bootstrap.
    Prends une liste (au lieu du content classique) car pas encore implémenté par sass (loop dans content)
    ---
    Recommandé pour les background-colors et les colors si on les veut lors de l'impression
**** */
@mixin style-compatible-print($style) {
    @each $propery, $value in $style {
        #{$propery}: $value;
    }
    @include print-mode {
        @each $propery, $value in $style {
            @if (ends-with(#{$value}, '!important')) {
                #{$propery}: $value;
            } @else {
                #{$propery}: $value !important;
            }
        }
    }
}

/* mixins utiles */
@mixin reset-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin reset-input-style {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@mixin reset-button-style {
    @include reset-input-style;
    padding: 0;
    border: none;
    background: none;
}

@mixin reset-select-style {
    @include reset-input-style;
    &::-ms-expand {
        display: none;
    }
}

@mixin reset-input-file-style {
    @include reset-input-style;
    &::file-selector-button {
        opacity: 0;
    }
}

@mixin lien-style-bootstrap {
    @include style-compatible-print((
        color: #337ab7
    ));
    text-decoration: none;
    &:hover, &:focus {
        color: #23527c;
        text-decoration: underline;
    }
}

@function rem($pixels) {
    $rem: math.div($pixels, $base-font-size);
    @return $rem + rem;
}

/* style copié / collé de font-awesome */
@mixin fa-icon($unicode) {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: $unicode !important;
}

/* animation présente dans l'accueil, ajouter la classe loaded en js pour déclencher l'animation d'apparition */
@mixin animation-load($nbr-items) {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity $time-lg ease, transform $time-lg ease;
    &.loaded {
        transform: translateY(0);
        opacity: 1;
    }
    @for $i from 1 to $nbr-items + 1 {
        &:nth-child(#{$i}) {
            -webkit-transition-delay: $i * 0.1s;
            transition-delay: $i * 0.1s;
        }
    }
}

@mixin transition-delay-childs($nbr-enfants, $delais-duree, $proprietes:all) {
    @for $i from 2 to $nbr-enfants + 1 {
        $transition-property: ();
        $transition-delay: ();
        @each $propriete in $proprietes {
            $transition-property: append(
                $transition-property, $propriete, $separator: comma
            );
            $transition-delay: append(
                $transition-delay, ($i - 1) * $delais-duree, $separator: comma
            );
        }
        &:nth-child(#{$i}) {
            -webkit-transition-delay: $transition-delay;
            transition-delay: $transition-delay;
            transition-property: $transition-property;
        }
    }
}

@mixin suppression-ligne-before($top: 17px) {
    &:before {
        content: "";
        position: absolute;
        top: $top;
        left: 0;
        border-bottom: 3px solid red;
        width: 100%;
        z-index: 999;
    }
}
