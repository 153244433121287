/* lors de la création d'une nouvelle variable de couleur, n'oubliez pas de l'ajouter à $couleurs-site */

$color-black: #393939;
$color-white: #fff;
$color-primary: #040073;
$color-primary-dark: #030052;
$color-secondary: #6ab12f;
$color-secondary-dark: #5b9729;
$color-grey-darker: #616161; /* menu ace */
$color-grey-dark: #7c7c7c;
$color-grey-blue: #cdd8e3;
$color-grey: #ccc;
$color-grey-light: #ddd;
$color-grey-light-ace: #e5e5e5;
$color-grey-lighter: #f9f9f9;
$color-grey-lighter-ace: #eee;
$color-grey-ace: #888;
$color-blue-light: #669fc7;
$color-blue: #5293c4;
$color-green: #87ba21;
$color-green-lighter:#daff8a;
$color-red: #d55b52;
$color-red-light: #eb0d0f;
$color-red-lighter:#f3d0cd;
$color-blue-action-principal: #80b9fe; /* boutons principaux (sauvegarder...) */

/* couleurs des fiches (résidentiel, agricole, non résidentiel, multirésidentiel) */
$color-r: #d15b47;
$color-r-light: #ffcc99;
$color-a: #808000;
$color-a-light: #eaffad;
$color-n: #98031f;
$color-n-light: #f2dede;
$color-m: #4c8fbd;
$color-m-light: #eef4f9;

$color-error: #a94442; /* color error de bootstrap */
$color-warning-light: #fcf8e3; /* color warning de bootstrap */
$color-warning-dark: #ffb752; /* color warning dans les boutons de ace */
$color-yellow-light: #fdffc0; /* couleur background explorateur ue évènement ouvert */

/* lie les couleurs à leur nom */
$couleurs-site: (
    "black": $color-black,
    "white": $color-white,
    "primary": $color-primary,
    "primary-dark": $color-primary-dark,
    "secondary": $color-secondary,
    "secondary-dark": $color-secondary-dark,
    "grey-darker": $color-grey-darker,
    "grey-dark": $color-grey-dark,
    "grey": $color-grey,
    "grey-light": $color-grey-light,
    "grey-lighter": $color-grey-lighter,
    "grey-light-ace": $color-grey-light-ace,
    "grey-lighter-ace": $color-grey-lighter-ace,
    "grey-ace": $color-grey-ace,
    "blue-light": $color-blue-light,
    "blue": $color-blue,
    "green": $color-green,
    "green-lighter": $color-green-lighter,
    "red": $color-red,
    "red-light": $color-red-light,
    "red-lighter": $color-red-lighter,
    "r": $color-r,
    "r-light": $color-r-light,
    "a": $color-a,
    "a-light": $color-a-light,
    "n": $color-n,
    "n-light": $color-n-light,
    "m": $color-m,
    "m-light": $color-m-light,
    "error": $color-error,
    "warning-light": $color-warning-light,
    "warning-dark": $color-warning-dark,
    "yellow-light": $color-yellow-light,
    "blue-action-principal": $color-blue-action-principal,
);
