/* créé une variable css pour chaque couleur (ex: --color-primary), permets aux fichiers css de s'en servir */
:root {
    @each $nom, $couleur in $couleurs-site {
        --color-#{$nom}: #{$couleur};
    }
}

body {
    background-color: $color-white;
}

.bg-none {
    background: none !important;
}

.border-none {
    border: none !important;
}

$directions-borders: top, left, right, bottom;
@each $nom, $couleur in $couleurs-site {
    .bg-#{$nom} {
        @include style-compatible-print((
            background-color: #{$couleur} !important
        ));
    }

    .text-#{$nom} {
        @include style-compatible-print((
            color: #{$couleur} !important
        ));
        &.d1, &.d2, &.d3, &.d4, &.d5, &.d6 {
            @include style-compatible-print((
                color: #{$couleur} !important
            ));
        }
    }

    .border-#{$nom} {
        border: 1px solid #{$couleur} !important;
    }

    @each $direction in $directions-borders {
        .border-#{$direction}-#{$nom} {
            border-#{$direction}: 1px solid #{$couleur} !important;
        }
    }
}

@each $direction in $directions-borders {
    .border-#{$direction}-none {
        border-#{$direction}: none !important;
        @include style-compatible-print((
            border-#{$direction}: none !important
        ));
    }
}

.text-gray {
    @include style-compatible-print((
        color: gray
    ));
}

.red-alert {
    @include style-compatible-print((
        color: #a94442
    ));
}

.blue {
    @include style-compatible-print((
        color: #478fca !important
    ));
}

.classique {
    @include style-compatible-print((
        background-color: #fcf6f9 !important
    ));
}

span.cycle-ouvert {
    @include style-compatible-print((
        background-color: #fff17a
    ));
}

.geocentralis-blue {
    @include style-compatible-print((
        color: #0050b2
    ));
}

.yellow {
    @include style-compatible-print((
        color: #f3d743 !important
    ));
}

.evenement-ouvert-jaune {
    @include style-compatible-print((
        background-color: $color-yellow-light !important
    ));
    td {
        @include style-compatible-print((
            background-color: $color-yellow-light !important
        ));
    }
}

.fill-white {
    fill: $color-white;
}

.border-1 {
    padding-top: 12px;
    padding-right: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    border: 1px solid $color-grey-light;
    margin-bottom: 12px;
}

.ombre {
    @include style-compatible-print((
        -moz-box-shadow: 1px 1px 3px #6b6b6b,
        -webkit-box-shadow: 1px 1px 3px #6b6b6b,
        box-shadow: 1px 1px 3px #6b6b6b,
        border: none 1px $color-black
    ));
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomleft: 5px;
    -moz-border-radius-bottomright: 5px;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.box-shadow-light {
    -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.06);
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.06);
}

.flex-caption-ombre {
    left: 2px;
    right: 3px;
    -moz-box-shadow: 1px 1px 3px #6b6b6b;
    -webkit-box-shadow: 1px 1px 3px #6b6b6b;
    box-shadow: 1px 1px 3px #6b6b6b;
    border: none 1px $color-black;
    -moz-border-radius-topleft: 0;
    -moz-border-radius-topright: 0;
    -moz-border-radius-bottomleft: 5px;
    -moz-border-radius-bottomright: 5px;
    -webkit-border-top-left-radius: 0;
    -webkit-border-top-right-radius: 0;
    -webkit-border-bottom-left-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.bg-gradient-primary {
    background: no-repeat, linear-gradient(141deg, #1e1d47 51%, $color-primary 75%) !important;
}

.border-black {
    border: 1px solid $color-black;
}

.border-2-black {
    border: 2px solid $color-black;
}
