body.login-layout {
    @extend .bg-gradient-primary;
}

.login-layout .widget-box {
    background-color: change-color($color-primary, $lightness: 42%, $saturation: 16%) !important;
}

.geocentralis-logo-login {
    padding-top: 40px;
    padding-bottom: 20px;
    width: 375px;
    max-width: 100%;
}

.geocentralis.login-purple-lamborghini {
    color: #3d0073;
}