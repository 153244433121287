.revenu-manuel-select2 {
    .select2-chosen {
        text-align: right;
    }
}

.select2-results .select2-result.select2-highlighted {
    background: $color-primary !important;
}

.select2-results .select2-result-unselectable {
    cursor: default;
}

/* styles spécifiques au select2 recherche rapide SIG */
.select2-recherche-propriete .select2-results .select2-result:not(:last-child):not(:nth-last-child(2)) {
    border-bottom: 1px solid $color-grey-light;
}

.select2-recherche-propriete .select2-results .select2-result:nth-child(even) {
    background-color: $color-grey-lighter;
}

.select2-recherche-propriete .select2-result-unselectable {
    position: sticky;
    bottom: 0;
    border-top: 1px solid $color-grey-light;
    background-color: $color-white;
}