input.ace.ace-switch.ace-switch-5[type="checkbox"] + .lbl::before {
    content: "Oui\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\Non";
}

input[type=checkbox], input[type=radio] {
    .ace.ace-w-check-margin + .lbl::before {
        margin-right: 6px;
    }
}

.readonly {
    color: #939192;
    background: #f5f5f5!important;
    pointer-events: none;
}

.sig-custom-form {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 5px;
    border-radius: 4px;
    background-color: $color-white;
    border: 2px solid rgba(0, 0, 0, 0.2);

    &.input {
        margin-top: 0;
        margin-right: 5px;
    }
}

/* OVERRIDE DES STYLES DE ACE */
label {
    &.radio {
        margin-right: 25px;
    }

    @include breakpoint-max(md) {
        text-align: left !important;
    }
}

.bigger-110-custom-checkbox {
    font-size: $font-size-content-md !important;
    margin-left: 6px !important;
    vertical-align: middle;
    padding-left: 10px !important;
}

/* style spécifique pour ce champ */
.form-date_condition_marche-wrap {
    width: 100px;
    text-align: center;
}

#remarque.champs-multiple.liste-over {
    display: table;
    width: 100%;

    [class*=’cell-remarque’] {
        float: none;
        display: table-cell;
    }
}

#form-remarque {
    color: $color-black;
    font-size: $font-size-content-lg;
}

div.sous-bloc-form.remarque {
    display: flex;
}

.entete-remarque {
    div {
        border: 1px solid $color-grey-light !important;
        background-image: linear-gradient(to bottom, #f8f8f8 0, #ececec 100%) !important;
    }
}

.cell-remarque.odd {
    background-color: $color-grey-lighter !important;

    textarea {
        background-color: $color-grey-lighter !important;

        &.cell-remarque.odd textarea:disabled {
            background-color: $color-grey-lighter !important;
        }
    }
}

.cell-nom-remarque {
    background-color: #FEF5D7 !important;
}

.cell-remarque {
    border: none !important;

    .form-control {
        border: none !important;
    }
}

.ace-file-input {
    .ace-file-container {
        &.selected {
            right: 0;
        }

        &:before {
            line-height: 30px;
        }

        .ace-icon {
            line-height: 28px;
        }

        .ace-file-name {
            line-height: 32px;
        }
    }
}

.ace-icon-evb {
    vertical-align: middle;
}

#evenement .checkbox label, .radio label {
    padding-left: 0;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
}

.form-group {
    input[type="color"],
    input[type="date"],
    input[type="datetime-local"],
    input[type="datetime"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select,
    textarea {
        background-image: none;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: 0 0;
        background-clip: border-box;
        background-origin: padding-box;
        background-size: auto auto;
    }

    > label[class*=col-] {
        padding-top: 4px;
        margin-bottom: 4px;
        padding-right: 10px !important;
        text-align: right;
    }
}

form {
    margin-top: 1em;
    @include print-mode {
        margin-top: 5px;
    }
}

.form {
    &.alert {
        padding: 10px;
        margin-top: 5px;
        margin-bottom: 0;
    }
}

.form-border-alert-danger {
    border-color: $color-red-light !important;
}

.form-control {
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
        color: #999999;
    }

    textarea[readonly] {
        /* style du SELECT tag readonly */
        background-color: blue;
    }
}

/* Option utilisée pour cacher les options qui ont été taggées comme étant disabled dans select2 */
.select2-container--default {
    .select2-results__option[aria-disabled=true] {
        display: none !important;
    }
}

/* Option utilisée pour cacher les options qui ont été taggées comme étant disabled dans select2 */
.select2-drop {
    .select2-results {
        .select2-disabled {
            display: none !important;
        }
    }
}

.select2-container {
    &.form-control {
        padding: 0 !important;
        height: auto;
        border: inherit;
    }

    .select2-choice {
        height: 34px;
        line-height: 32px;
    }
}

.select2-container-disabled {
    a {
        cursor: not-allowed;
    }
}

.select2-results {
    max-height: 400px;
}

.select2-search-choice-close {
    &:before {
        display: inline;
        position: relative;
    }
}

select {
    &.form-control {
        &.select-fake {
            -webkit-appearance: none;
            -moz-appearance: none;
            text-align: center !important;
            @include style-compatible-print((
                background-color: #c0c0c0 !important
            ));
        }

        option {
            /* pour overrider le setting du ACE template. ATTENTION: n'est pas pris en charge par IE */
            /* overridé car en Chrome, les listes avec plusieurs optgroup étaient coupés en hauteur */
            padding: 0 4px;
        }
    }

    &[disabled] {
        &.form-control {
            &.select-fake {
                background-color: #c0c0c0 !important;
            }
        }
    }
}

h6 {
    &.form {
        color: #549dca;
        font-size: $font-size-content-md;
        line-height: 1.5;
        font-weight: bold;
    }
}

input[type=radio].ace + .lbl::before {
    line-height: 15px;
}

.input {
    font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
    font-size: 0.8em;
}

textarea {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    resize: none;
    overflow-x: hidden;

    &.textarea-resizable {
        resize: vertical;
    }

    &:not(.money) {
        &:not(.clearable) {
            overflow-x: hidden;
        }
    }

    &.form-control:not([rows]) {
        height: 34px;
    }
}

::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
}

::-moz-placeholder {
    opacity: 1;
    -moz-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
}

:-ms-input-placeholder {
    opacity: 1;
    -ms-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
}

:focus::-webkit-input-placeholder {
    opacity: 0.5;
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
}

:focus:-moz-placeholder {
    opacity: 0.5;
    -moz-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
}

:focus::-moz-placeholder {
    opacity: 0.5;
    -moz-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
}

:focus:-ms-input-placeholder {
    opacity: 0.5;
    -ms-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
}

input {
    &[name="placeholder"] {
        color: #999 !important;
    }

    &[type=file] {
        border-radius: 0 !important;
        color: #858585;
        background-color: $color-white;
        border: 1px solid #d5d5d5;
        padding: 5px 4px 6px;
        font-size: $font-size-content-md;
        font-family: inherit;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        -webkit-transition-duration: .1s;
        transition-duration: .1s
    }

    &.money, &.superficie {
        text-align: right !important;
    }

    &.search-query {
        color: #999 !important;
    }

    &[type=radio] {
        display: inline-block;
    }

    &.aspect-label {
        border-style: none;
        background-color: transparent !important;
        cursor: auto !important;
        height: 21px;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.flex-caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10px;
    background: rgba(0, 0, 0, 0.3);
    padding: 5px;
    color: $color-white;
    text-align: center;

}

.li-caption {
    width: 100%;
    position: relative;
    opacity: 1;
    display: block;

}

div[type=formulaire] {
    label {
        font-size: 13.5px;
        line-height: 26px;
    }
}

/* lc-swich - custom checkbox plugin */
div {
    &.lc_wrap {
        height: 20px !important;
    }

    &.lcs_switch {
        width: 65px !important;
        height: 24px !important;

        &.lcs_off {
            &:hover {
                background: #929292;
            }
        }

        &.lcs_on {
            &:hover {
                background: #4a872f;
            }

            .lcs_cursor {
                left: 45px !important;
            }

            .lcs_label_on {
                left: 8px !important;
            }
        }
    }

    &.lcs_cursor {
        top: 5px !important;
        width: $spacing-sm !important;
        height: $spacing-sm !important;
    }
}

.fake-number-input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type=number] {
        /* Firefox */
        -moz-appearance: textfield;
    }
}

.fake-text-input {
    border-color: #d5d5d5 !important;
    background-color: $color-white !important;
    color: $color-black;
    padding: 5px 10px;
    min-height: 34px;
    font-size: $font-size-title-md;
    margin-bottom: $spacing-sm;
}

.unselectable {
    -moz-user-select: -moz-none;
    -moz-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.validation .checkbox_custom input:checked ~ .checkmark {
    background-color: #60ca42;
}

.validation-checkbox-invalide,
.validation-checkbox-invalide-alt {
    background: red !important;
}

.validation-checkbox-invalide-alt {
    display: flex;
    align-items: center;
    justify-content: center;
}

/*Gestion des checkboxes pour section documents comme dans l'éditeur de fiche*/
.div-ctrl-suppression {
    input[type="checkbox"] {
        visibility: hidden;
    }
    .for-ctrl-suppression-w-hidden-input {
        input[type="checkbox"] {
            display: none;
        }
    }
}

.champs-multiple {
    input {
        &[type="checkbox"]:not(.checkboxmulti) {
            margin: 0;
        }

        &.form-control.ctrl-suppression[type="checkbox"] {
            visibility: hidden;
        }
    }

    label {
        &.for-ctrl-suppression {
            display: block;
            margin-bottom: 0;
            line-height: 34px;

            > i {
                left: 0;
                position: absolute;
                top: 5px;
                z-index: 99;

                &.fa-square-o {
                    color: $color-black;
                }

                &.fa-trash-o {
                    color: red;

                    &.tout-active {
                        color: red;
                    }

                    &.tout-desactive {
                        color: $color-black;
                    }
                }
            }

            &[disabled] {
                cursor: not-allowed;
            }
        }

        &.for-ctrl-suppression-alt {
            display: initial;
            line-height: initial;
            margin-left: 0;

            > i {
                margin-top: 4px;
                position: static;
            }
        }
    }

    div {
        &.suppression-ligne,
        &.suppression-ligne-sauvegardee {
            &:not(.ctrl2-sup-alt) {
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    top: 17px;
                    left: -2px;
                    height: 3px;
                    width: calc(100% + 4px);
                    background-color: red;
                    z-index: 50;
                }
            }
        }
    }

    .header-suppression {
        color: $color-black;
    }

    .div-header-suppression {
        &.flex-full-center {
            @extend .flex-full-center;
        }
    }
}

.ligne.nouvelle-ligne {
    .for-ctrl-suppression, .control-label-suppression {
        display: none !important;
    }
}
.form-w-responsive-labels {
    .control-label {
        word-break: break-word;
        &:not(.label-desktop) {
           display: none;
        }
    }
    .ctrl-suppression {
        display: none;
    }
    label.for-ctrl-suppression-alt>i {
        margin-top: 4px;
    }
    @include breakpoint-max(lg) {
        &:not(.form-w-responsive-labels-print) {
            .control-label {
                font-size: $font-size-content-sm;
                display: none;
                &:not(.label-desktop) {
                    display: initial;
                    &.flex-full-center {
                        display: flex;
                    }
                }
            }
            .label-desktop-wrapper {
                display: none;
            }
        }

        div.suppression-ligne:not(.form-group-suppression) {
            &:before {
                top: 35px;
            }
        }
    }
}

.form-fdt {
    select.form-control[readonly] {
        pointer-events: none;

    }
    textarea.form-control[readonly] {
        &:focus {
            background-color: $color-grey-light-ace;
        }
    }
    .form-control[readonly] {
        pointer-events: auto;
    }
    .control-label {
        font-weight: 700;
    }
    .totaux-form-item-label {
        font-weight: 700;
    }
    .select2-container {
        .select2-choice {
            abbr:before  {
                top: calc(50% - 8px);
            }
        }
    }
    .nouvelle-ligne {
        .for-ctrl-suppression, .control-label-suppression {
            display: none !important;
        }
    }
    .form-group {
        margin-bottom: 0;
        &.has-error {
            .select2-choice, textarea, input {
                border-color: $color-red-light !important;
            }
        }
        &:not(.has-error) {
            .help-block {
                display: none;
            }
        }
    }
    .help-block {
        margin-top: 5px;
        margin-bottom: 0;
        padding: 5px;
        background-color: #f2dede;
        border: 1px solid #ebccd1;
        strong {
            color: #a94442 !important;
            font-weight: 400;
            word-break: break-word;
        }
    }
    &.form-fdt-print {
        .form-control[disabled],
        .form-control[readonly],
        fieldset[disabled] .form-control,
        input[readonly] {
            color: $color-black !important;
            background-color: $color-white !important;
        }
        .row-totaux {
            margin-left: -20px;
        }
        .row-totaux-offset-print {
            margin-left: -25px;
        }
        .row-totaux, .col-totaux {
            .form-control[disabled],
            input[readonly] {
                color: $color-black !important;
                background-color: $color-grey-lighter-ace !important;
            }
        }
        .nouvelle-ligne {
            display: none;
        }
        .widget-header {
            color: $color-black;
        }
    }
}

div {
    &.suppression-ligne {
        &:not(.form-group-suppression):not(.ctrl2-sup-alt) {
            &:before {
                content: "";
                position: absolute;
                top: 17px;
                left: -2px;
                height: 3px;
                width: calc(100% + 4px);
                background-color: red;
                z-index: 50;
            }
        }
        &.form-group-suppression {
            .fa-trash-o:before {
                color: red;
            }
        }
    }
}

.form-vertical {
    div.suppression-ligne {
        &:before {
            display: none;
        }
        .controls {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 15px;
                left: 0;
                right: 0;
                height: 3px;
                background-color: red;
                z-index: 50;
            }
        }
    }
    .row {
        .controls {
            &:before {
                left: -15px;
                right: -15px;
            }
        }
        &.row-padding-5 {
            .controls {
                &:before {
                    left: -2.5px;
                    right: -2.5px;
                }
            }
        }
    }
}

input.form-control.ctrl-checkbox[type="checkbox"] {
    visibility: hidden;
}

label.for-ctrl-checkbox {
    display: block;
    margin-bottom: 0;
    line-height: 34px;

    > i {
        left: 0;
        position: absolute;
        top: 5px;
        z-index: 99;
    }
}

label.for-ctrl-checkbox[disabled],
div.suppression-ligne:hover,
div.suppression-ligne-sauvegardee:hover {
    cursor: not-allowed;
}

.for-ctrl-suppression:not([disabled]), form.form-fdt {
    i.fa-square-o, i.fa-trash-o {
        &:hover {
            &:before {
                content: "\f014" !important;
                color: #cdcdcd !important;
                -webkit-transition: color .10s linear, background-color .10s ease-in-out, border-color .10s ease-in-out;
                -moz-transition: color 10s linear, background-color .10s ease-in-out, border-color .10s ease-in-out;
                -o-transition: color 10s linear, background-color .10s ease-in-out, border-color .10s ease-in-out;
                transition: color .10s linear, background-color .10s ease-in-out, border-color .10s ease-in-out;
            }
        }
    }
}

.div-ctrl-suppression-hidden {
    display: none;
}

/* checkbox du wizard - Custom - Début */
.checkbox_custom {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &label {
        &.multiple {
            font-size: $font-size-content-md;
            font-family: inherit;
            margin-left: 10px;
        }
    }

    input {
        /* Cacher le checkbox du formulaire */
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
            ~ .checkmark {
                background-color: #428bca;

                &:after {
                    display: block;
                }
            }
        }
    }

    &:hover {
        input ~ .checkmark {
            background-color: #929292;
        }
    }

    .checkmark:not(.validation-checkbox-invalide-alt):after {
        left: 9px;
        top: 5px;
        width: 7px;
        height: 12px;
        border: solid $color-white;
        border-width: 0 3.5px 3.5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.checkmark {
    position: absolute;
    top: 0;
    left: 12px;
    height: 25px;
    width: 25px;
    background-color: $color-grey;

    &:after {
        content: "";
        position: absolute;
        display: none;
    }
}

span.checkbox_custom-multiple {
    font-size: $font-size-content-md;
    font-family: inherit;
    margin-left: 10px;
}

.validation-checkbox-invalide-alt {
    &:after {
        @include fa-icon('\f00d');
        color: $color-white;
        font-size: $font-size-title-sm;
    }

    &.checkmark:after {
        display: block;
    }
}

.supprimer-fichier:hover {
    color: #ff0000 !important;
    transform: scale(1.2);
}

#accordion-fichier-a-importer {
    td {
        vertical-align: middle;
    }

    .checkmark {
        top: -7px;
    }
}

/* checkbox du wizard - Custom - Fin */

#erreurs-generiques {
    text-align: left;
}

.erreur-validation {
    position: absolute;
    z-index: 999;
    top: -20px;
    right: 0;
    line-height: 0.4;
    border-radius: 15px;
    border: 2px solid;
    font-size: $font-size-content-sm;

    &.alert-danger {
        border-color: $color-red-light !important;
    }

    &.alert-warning {
        border-color: #c0a16b !important;
    }
}

.erreur-validation-border-danger {
    border-color: $color-red-light !important;
}

.erreur-validation-border-warning {
    border-color: #c0a16b !important;
}

.alert-warning {
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-color: #D8D045 !important;
    @include style-compatible-print((
        background-color: #fffac4 !important,
        color: #8a6d3b !important
    ));
    strong {
        @include style-compatible-print((
            color: #8a6d3b !important
        ));
    }
}

.alert-caution {
    @extend .alert-warning;
    border-color: $color-warning-dark !important;
    @include style-compatible-print((
        background-color: lighten($color-warning-dark, 20%) !important,
        color: #8a6d3b !important
    ));
    strong {
        @include style-compatible-print((
            color: #8a6d3b !important
        ));
    }
}

#contenu {
    .ctrl2 input:not(.money) {
        /* aligné à gauche les textes des inputs dans le wizard */
        text-align: left;
    }

    .date-picker.controle-modifie[name=date_evenement][disabled] {
        background-color: $color-grey-lighter-ace !important;
    }

    .wiz-validation-warning .alert.alert-warning {
        padding: 5px;
        margin-bottom: 10px;
    }
}

/* DM: HACK pour cacher les scrollbars pas rapport des SELECT liste */
.container-select-multiple,
.container-select-multiple-input-multiple {
    select::-webkit-scrollbar {
        background-color: transparent
    }
}

#valider-importer-donnees-evenement-en-lot .select2-container .select2-choice .select2-arrow {
    right: 5px !important;
}

#b98-repartition-fiscale {
    .sous-bloc-form {
        input[disabled] {
            background-color: $color-white !important;
        }
    }

    .section-header {
        font-weight: bold;

        hr {
            border-color: $color-black;
            margin-top: 5px;
        }
    }

    .divTable-fake {
        .header, .body {
            margin-left: 0;
        }
    }
}

#b94-valeur-retenue, #accordion-valeur-retenue-section {
    .row {
        &.section-header {
            font-weight: bold;
        }

        .section-header {
            text-align: left;
            font-weight: bold;
        }

        .section-header-padding-40 {
            text-align: left;
            font-weight: bold;
            padding-left: 40px;
        }

        .section-header-padding-60 {
            text-align: left;
            font-weight: bold;
            padding-left: 60px;
        }
    }
}

#b94-valeur-retenue {
    .row.hidden-row {
        visibility: hidden;
    }

    label.for-ctrl-checkbox > i {
        left: inherit;
        position: absolute;
        top: 5px;
        z-index: 99;
    }

    &.blocForm {
        margin-top: 0;
    }
}

#accordion-valeur-retenue-section {
    .row {
        .section-header.lbl,
        .section-header-padding-40.lbl,
        .section-header-padding-60.lbl {
            height: 34px;
        }
    }
}

#adm-table-pilotage {
    .form-control {
        background-color: unset;
        display: table-cell;
        width: 75%;
    }
}

.checkbox_square_down {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        /* Cacher le checkbox du formulaire */
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:disabled {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            cursor: not-allowed;
        }

        &:checked {
            ~ .checkmark_square_down {
                color: $color-blue-action-principal;
            }
        }
    }

    &:hover {
        input {
            ~ .checkmark_square_down {
                color: #7F7F7F;
            }

            &:checked {
                ~ .checkmark_square_down {
                    color: #4f99c6;
                }
            }
        }
    }
}

.checkmark_square_down {
    position: absolute;
    top: 0;
    left: 12px;
    height: 25px;
    width: 25px;
    color: #929292;

    &:after {
        @include fa-icon("\f150");
        font-size: 2em;
        position: absolute;
    }
}

span.checkmark_square_down {
    &[disabled] {
        cursor: not-allowed;
    }
}

.select-disabled {
    background-color: $color-white !important;
}

.bloc-form-info {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.bloc-form-item {
    &:empty {
        display: none;
    }

    &:not(:first-child):before {
        display: inline;
        content: " / ";
    }
}

.col-xs-12.col-md-7.suppression-ligne-test {
    text-decoration: line-through;
    text-decoration-color: red;
    text-decoration-thickness: 3px;
}

.filterOptionsterrain {
    display: none;
}

#controleUserPreferenceMapLayerSave {
    margin-right: 2px;
}

#col-xs-12.col-md-7.suppression-ligne-test:hover .filterOptionsterrain {
    display: block;
}

/* inline responsive form specific style - timesheet */
.responsive-form {
    .mobile-label {
        display: none;
    }

    @include breakpoint-max(lg) {
        .generic-form-label {
            display: none !important;
        }
        .mobile-label {
            display: block;
            font-size: $font-size-content-sm;
        }
    }
    @include breakpoint-max(sm) {
        .mobile-label {
            font-size: $font-size-content-md;
        }
    }
}

/* custom-checkbox */
.custom-checkbox-container {
    display: flex;
    align-items: center;
}

.custom-checkbox-label {
    margin-right: 10px;
}

.custom-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 20px;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .custom-checkbox-checkbox {
        /* Hide the browser's default checkbox */
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &:hover {
        ~ .custom-checkbox-checkmark {
            opacity: .8;
        }
    }
}

.custom-checkbox-checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before {
        @include fa-icon("\f096");
        position: absolute;
        display: inline-block;
        color: $color-black;
    }

    &:after {
        /* Create the checkmark/indicator (hidden when not checked) */
        @include fa-icon("\f00c");
        position: absolute;
        display: none;
        color: #3a87ad;
        font-size: $font-size-title-md;
    }
}

.custom-checkbox-checkbox:checked ~ .custom-checkbox-checkmark:after {
    display: block;
}

.offset-checkbox {
    position: relative;
    .offset-checkbox-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;
    }
    .offset-checkbox-checkbox {
        &:after {
            @include fa-icon('\f096');
            font-size: 28px;
        }
    }
    .offset-checkbox-input:checked ~ .offset-checkbox-checkbox {
        &:after {
            @include fa-icon('\f046');
            font-size: 28px;
        }
    }
    &.form-control {
        display: flex;
        align-items: center;
        padding: 0;
        color: $color-black;
        border: none;
    }
}

span.asteriskField {
    color: indianred;
}

/* custom filters radio */
.filter-radio {
    margin-left: 0;
    list-style-type: none;

    .filter-radio-input {
        display: none;
    }

    .filter-radio-label {
        cursor: pointer;
        color: $color-black;
        position: relative;
        background-color: $color-white;
        padding: 5px 5px 5px 30px;
        width: 100%;
        transition: color $time-md ease, background-color $time-md ease;

        .filter-radio-check {
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            transition: opacity $time-md ease;
        }

        &:hover, &:focus {
            background-color: #ededed;
        }

        &:active, &:active:focus {
            background-color: #d9d9d9;
        }
    }
}

.filter-radio-input:checked + .filter-radio-label {
    color: $color-white;
    background-color: $color-secondary !important;

    .filter-radio-check {
        opacity: 1;
    }
}

.selected-filter-remove {
    border: none;
    background: none;
    padding: 0;
}

.custom-file-input-container {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:after {
        @include fa-icon('\f0c6');
        @include lien-style-bootstrap;
        font-size: 1.333em;
        line-height: .75em;
        vertical-align: -15%;
        text-decoration: none;
        pointer-events: none;
        transition: all .1s;
        &:hover, &:focus {
            transform: scale(1.2);
        }
    }
    .custom-file-input {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    &.edit-mode {
        &:after {
            content: url(/static/images/file-checked.svg) !important;
            width: 20px;
        }
    }
}

.input-fake {
    border-radius: 0!important;
    @include style-compatible-print((
        background-color: $color-white
    ));
    border: 1px solid #d5d5d5;
    padding: 5px 4px 6px 4px;
    min-height: 34px;
    font-size: 14px;
    font-family: inherit;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    -webkit-transition-duration: .1s;
    transition-duration: .1s;
}

.input-calendar {
    .controls {
        position: relative;
        input {
            padding-right: 44px;
        }
        &:before {
            @include fa-icon("\f073");
            position: absolute;
            right: 0;
            top: 0;
            pointer-events: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 34px;
            padding: 6px 12px;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            color: #555;
            text-align: center;
            background-color: #eee;
            border: 1px solid #ccc;
        }
    }
}

.champs-multiple-w-big-labels {
    .generic-form-label {
        min-height: 39px;
    }
    &.champs-multiple {
        div {
            &.suppression-ligne-sauvegardee,
            &.suppression-ligne {
                &:before {
                    top: 55px;
                }
            }
        }
    }
}

.form-without-margin-bottom {
    .form-group {
        margin-bottom: 0;
    }
}

.input-width-100 {
    input, select, textarea {
        width: 100%;
    }
}

.text-vcenter-input {
    display: flex;
    align-items: center;
    height: 34px;
}

.fake-select {
    display: grid;
    grid-template-columns: max-content;
    background-color: transparent !important;
    list-style: none;
    text-align: left;
    font-size: 12px;
    margin-left: 0;
    margin-bottom: 0;
    padding: 0;
    height: auto;
    border: none;
    color: $color-black;
    .fake-select-option {
        padding-left: 2px;
        cursor: pointer;
        &:hover, &:focus {
            background-color: $color-grey-light-ace;
        }
        &[disabled] {
            pointer-events: none;
            cursor: not-allowed;
        }
        &.selected {
            background-color: $color-grey;
        }
    }
}

.input-icon {
    input{
        padding-left:24px;
        padding-right:6px
    }
}

.radio-no-margin {
     .radio {
         label {
             padding-left: 20px;
         }
         input[type=radio] {
             margin-left: 0;
             left: 0;
         }
     }
}
