.fa-pdf-evb {
    content: url(/static/images/pdf.png);
    height: 30px;
}

.fa-pdf-evb:before { /* Uniquement pour Firefox */
    content: url(/static/images/pdf_firefox.png);
    height: 25px;
}

.fa-gif-evb {
    content: url(/static/images/gif.png);
    height: 32px;
}

/* Faire version pour Firefox */

.fa-jpg-evb {
    content: url(/static/images/jpg.png);
    height: 32px;
}

.fa-regular-eraser-evb {
    content: url(/static/images/fa-regular-erase.svg);
    width: 14px;
}

.fa-file-checked {
    content: url(/static/images/file-checked.svg);
}

.tape_a_mesurer {
    content: url(/static/images/tape_a_mesurer.svg);
    margin-top: 40%;
    width: 21px;
}

.fa-jpeg-evb {
    content: url(/static/images/jpg.png);
    height: 18px;
}

.fa-doc-evb {
    content: url(/static/images/doc.png);
    height: 32px;
}

.fa-jnt-evb {
    content: url(/static/images/jnt.png);
    height: 32px;
}

.fa-zip-evb {
    content: url(/static/images/zip.png);
    height: 32px;
}

.fa-exclamation-bubble-evb {
    content: url(/static/images/exclamation.svg);
    width: 14px;
}

/* Hack pour font awesome */
.menu-min .fa-support-evb {
    margin-left: 12px;
}

.fa-support-evb-icone-black {
    content: url(/static/images/support_icone.png);
    vertical-align: middle;
    width: 13px !important;
    margin-right: 6px;
}

.fa-support-evb-white {
    content: url(/static/images/support-white.png);
    vertical-align: middle;
    width: 37px !important;
    margin: 0 auto !important;
}

.fa-support-evb-white:before { /* Uniquement pour Firefox */
    content: url(/static/images/support-white-ff.png);
    transform: scale(0.5);
    vertical-align: middle;
    margin: 0 auto !important;
}

.fa-teamviewer-evb-white {
    content: url(/static/images/teamviewer-white.png);
    vertical-align: middle;
    width: 66px !important;
    margin: 0 auto !important;
}

.fa-teamviewer-evb-white:before { /* Uniquement pour Firefox */
    content: url(/static/images/teamviewer-white-ff.png);
    transform: scale(0.5);
    vertical-align: middle;
    margin: 0 auto !important;
}

.teamviewer-svg {
    content: url(/static/images/team-viewer.svg);
    width: 18px;
    margin-right: 6px;
}

.fa-csv-evb {
    content: url(/static/images/csv.png);
    height: 32px;
}

.fa-docx-evb {
    content: url(/static/images/doc.png);
    height: 32px;
}

.fa-docx-evb:before {
    /* Mettre le contenu en base64 parce que URL pas pris en compte ni par Firefox et IE. */
    content: ''; /* Mettre url(/static/images/doc.png); */
}

.fa-xls-evb {
    content: url(/static/images/xls.png);
    height: 32px;
}

.fa-xlsx-evb {
    content: url(/static/images/xls.png);
    height: 32px;
}

.fa-flat-evb {
    content: url(/static/images/flat.png);
    height: 32px;
}

.fa-small {
    font-size: 0.7em;
}

.fa-square-o:before {
    content: "\f096";
}

i.exp-ue-historique-recherche-liste {
    color: $color-blue-action-principal;
}

i.exp-ue-historique-recherche-liste:hover {
    color: #457aa3 !important;
}

.fa-disabled {
    opacity: 0.4;
    cursor: not-allowed;
}
