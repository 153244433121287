@keyframes shake {
    0% { transform: translateX(0); }
    0% { transform: translateX(2px); }
    10% { transform: translateX(-2px); }
    15% { transform: translateX(0); }
    100% { transform: translateX(0); }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.animate-red:link {
    -webkit-transition: color $time-xs linear, background-color $time-xs ease-in-out, border-color $time-xs ease-in-out;
    -moz-transition: color $time-xs linear, background-color $time-xs ease-in-out, border-color $time-xs ease-in-out;
    -o-transition: color $time-xs linear, background-color $time-xs ease-in-out, border-color $time-xs ease-in-out;
    transition: color $time-xs linear, background-color $time-xs ease-in-out, border-color $time-xs ease-in-out;
}

.animate-red:hover {
    color: red !important;
    border-color: red !important;
    -webkit-transition: color $time-xs linear, background-color $time-xs ease-in-out, border-color $time-xs ease-in-out;
    -moz-transition: color $time-xs linear, background-color $time-xs ease-in-out, border-color $time-xs ease-in-out;
    -o-transition: color $time-xs linear, background-color $time-xs ease-in-out, border-color $time-xs ease-in-out;
    transition: color $time-xs linear, background-color $time-xs ease-in-out, border-color $time-xs ease-in-out;
}

.slide-right {
    width: 16.666%;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -ms-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
    &.hidden {
        width: 0;
        -webkit-transition: width 1s ease-in-out;
        -moz-transition: width 1s ease-in-out;
        -ms-transition: width 1s ease-in-out;
        -o-transition: width 1s ease-in-out;
        transition: width 1s ease-in-out;
    }
}

.animate-shake {
    animation: shake 3s linear infinite;
}
