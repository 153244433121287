%border-bg-camera {
    background-color: transparentize($color-black, .1);
    border: 4px solid transparentize($color-black, .1);
}

%btn-round-camera {
    @include reset-input-file-style;
    cursor: pointer;
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50% !important;
    color: $color-white !important;
    @extend %border-bg-camera;
    transition: all $time-md ease !important;
    &:after {
        @include fa-icon("\f00d");
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 45px;
    }
    &:hover, &:focus {
        background-color: transparentize($color-black, .4);
    }
    &:active, &:active:focus {
        background-color: $color-black;
    }
}

.camera-video-container {
    position: relative;
    @extend %border-bg-camera;
    .camera-video {
        max-width: 100%;
        min-height: 150px;
        max-height: calc(100vh - 280px);
        transition: all $time-md ease !important;
    }
}

.camera-replace-container {
    position: relative;
    background-color: lighten($color-black, 7);
    .camera-replace-remove {
        @extend %btn-round-camera;
        position: absolute;
        right: 50px;
        top: 50px;
    }
    .camera-photo {
        max-width: 100%;
    }
}

.caroussel-camera {
    @extend %border-bg-camera;
    .caroussel-camera-slide {
        position: relative;
        .caroussel-camera-slide-remove {
            @extend %btn-round-camera;
            position: absolute;
            right: 10px;
            top: 8px;
            width: 25px;
            height: 25px;
            &:after {
                font-size: 14px;
            }
        }
    }
}

.camera-buttons-container {
    position: absolute;
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .camera-button {
        @extend %btn-round-camera;
        position: relative;
        width: 80px;
        height: 80px;
        padding: 0;
        margin: 0 15px;
        &:after {
            font-size: 45px;
        }
        &.camera-button-file {
            &:after {
                content: "\f093" !important;
            }
        }
        &.camera-button-picture {
            display: none;
            &:after {
                content: "\f030" !important;
            }
        }
    }
}

.avec-camera {
    .camera-buttons-container {
        .camera-button {
            &.camera-button-picture {
                display: block;
            }
        }
    }
}
