/*STYLES COPIÉS DU TEMPLATE POUR REPRODUIRE UN MENU DE GAUCHE RESPONSIVE (QUI DISPARAIT SUR TABLET)*/
/* responsive sidebar */
.evb-sidebar {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: $color-white;
    border-color: $color-grey;
    border-image: none;
    border-style: solid;
    border-width: 0 1px 0 0;
    float: left;
    position: relative;

    @include breakpoint-max(md) {
        display: none;
        float: none;
        position: absolute;
        z-index: 999;
        left: 0;
        bottom: auto;
        top: auto !important;
        /*so that it's applied even if it's .sidebar-fixed */

        margin-top: 40px;
        border: 1px solid $color-grey;
        border-left-width: 0;
        box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
        &:before {
            display: none;
        }
        &.display {
            display: block;
        }
    }
}

.sidebar {
    .sidebar-shortcuts {
        min-height: 39.5px;
    }
    /* responsive sidebar fixed styling */
    @include breakpoint-max(xs) {
        &.responsive {
            &.sidefixed-responsive {
                top: 52px;
                bottom: 0;
                width: 100%;
                z-index: 2000;
                .nav-list {
                    > li {
                        > a {
                            height: auto;
                            font-size: $font-size-content-md;
                            padding-top: 12px;
                            padding-bottom: 12px;
                        }
                        &.active:after {
                            right: 0;
                        }
                    }
                    li.active > a:after {
                        right: 2px;
                    }
                }
            }
        }
    }
}

.version_info {
    font-size: 9pt;
}

.no-skin {
    .nav-list {
        > li {
            &.active {
                > a {
                    font-weight: 700;
                    color: $color-primary;
                }
                &:after {
                    border-color: $color-primary;
                }
            }
            &:hover {
                > a {
                    color: $color-primary;
                }
            }
            .submenu {
                > li.active > a {
                    color: $color-primary;
                    text-decoration: none;
                }
            }
        }
        li {
            &.active {
                > a {
                    &:after {
                        border-right-color: $color-primary;
                        -moz-border-right-colors: $color-primary;
                    }
                }
            }
        }
    }
}

body.sidebar-open {
    @include breakpoint-max(xs) {
        height: 100vh;
        overflow: hidden;
    }
}

.sidebarTitle {
    font-size: 1.0em;
}
