body {
    font-family: $font-family-content;
    font-size: $base-font-size;
}

h1, h2, h3, h4, h5, h6, .d1, .d2, .d3, .d4, .d5, .d6 {
    font-weight: 400;
    font-family: $font-family-content;
}

h1, .d1 {
    font-size: $font-size-title-lg !important;
    font-weight: 400 !important;
    color: $color-primary !important;
}

h4, .d4 {
    font-size: $font-size-title-md!important;
}

h5, .d5 {
    font-size: $font-size-title-sm !important;
    font-weight: 400 !important;
}

.text-lg {
    font-size: 17px !important;
}

.text-bold {
    font-weight: bold !important;
}

.bold {
    font-weight: bold;
}

.not-bold {
    font-weight: normal;
}

.capitalize {
    text-transform: capitalize;
}

.lower_capitalize {
    text-transform: lowercase;
}

.lower_capitalize:first-letter {
    text-transform: uppercase;
}

.text-italic {
    font-style: italic;
}

.text-decoration-underline {
    text-decoration: underline !important;
}

.text-16 {
    font-size: $font-size-content-lg;
}

.text-small {
    font-size: $font-size-content-sm;
}

.line-height-base {
    line-height: $base-line-height !important;
}

.text-decoration-none {
    text-decoration: none;
}

.word-break {
    word-break: break-word;
    hyphens: auto;
}

p {
    a {
        color: #428BCA;
        text-shadow: 2px 0 0 #f2f6ed, 1px 0 0 #f2f6ed, -1px 0 0 #f2f6ed, -2px 0 0 #f2f6ed;
        background-image: linear-gradient(to bottom, currentColor 0%, currentColor 100%);
        background-repeat: repeat-x;
        background-position: 0 95%;
        -webkit-background-size: 100% 0.5px;
        background-size: 100% 1px;
        &:hover, &:focus {
            color: #428BCA;
            background-image: linear-gradient(to bottom, currentColor 0%, currentColor 0%);
            background-size: 0 0;
            text-decoration: none;
        }
    }
}

.list-inline, .list-unstyled {
    margin-left: 0;
    color: $color-black;
    font-weight: initial;
    padding-top: $spacing-sm;
    padding-left: $spacing-sm;
}

.reset-list {
    @include reset-list;
    ul {
        @include reset-list;
    }
}

ul {
    &.no-bullets {
        list-style-type: none;
    }
    &.margin-left {
        margin-left: 50px;
    }
    &.no-margin {
        margin-left: 0;
    }
}

.text-warning {
    font-family: $font-family-content;
    padding-top: 10px;
}

$directions-texte: left, center, right;
@each $direction in $directions-texte {
    .text-#{$direction} {
        text-align: #{$direction} !important;
    }
}

.center {
    text-align: center;
}

.text-v-center {
    vertical-align: middle;
    display: inline-block;
}

.upper {
    text-transform: uppercase;
}

.note2 {
    word-wrap: break-word;
}

.evb-ficheData1 {
    color: $color-black;
    font-family: Arial;
}

.text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}
