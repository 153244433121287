.cursor-pointer {
    cursor: pointer;
}

.cursor-grab {
    cursor: grab !important;
}

.cursor-grabbing {
    cursor: grabbing !important;
}

.cursor-not-allowed {
  cursor: not-allowed!important;
}

.pointer-events-none {
    pointer-events: none;
}

.reset-list {
    @include reset-list;
}

.breadcrumbs {
    background-color: $color-white;
    line-height: 36px;
    margin-top: 4px;
    min-height: 36px;
}

.popover-content {
    line-height: 20px;
    font-size: 11px;
}

/*PERMET DE REMETTRE LE CALENDRIER DEVANT LA FENÊTRE MODAL DANS CHROME*/
.datepicker-dropdown {
    z-index: 10000 !important;
}

div[data-type="travaux-batiment-nature-contenu"] {
    .panel-body {
        @include style-compatible-print((
            background-color: #F6F6F6
        ));
    }
    > [data-type="travaux-batiment-nature-type"] {
        &:nth-child(odd) {
            @include style-compatible-print((
                background-color: $color-grey-light-ace
            ));
            padding-top: $spacing-sm;
        }
        &:nth-child(even) {
            @include style-compatible-print((
                background-color: $color-grey-lighter-ace
            ));
            padding-top: $spacing-sm;
        }
    }
}

/* icones pieds ou mètres dans SIG */
.custom-pieds, .custom-metrique {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    &:after {
        font-size: $font-size-content-lg;
        font-family: serif;
        font-weight: 600;
    }
}

.custom-pieds {
    &:after {
        content: "pi";
    }
}

.custom-metrique {
    &:after {
        content: "m";
    }
}

/*ON ENLÈVE LA ROTATION APPLIQUÉE AUX ICÔNES DE DOWNLOAD DANS LE FUELUX TREE*/
.tree {
    .ace-icon[class*="-down"] {
        transform: rotate(0deg);
    }
}

.action-grille-icone {
    @include breakpoint-max(sm) {
        padding: 10px;
    }
    @include breakpoint-min(sm) {
        padding: 0;
    }
}

.breadcrumbs {
    margin-bottom: 0;
}

.ruban-action-datatables .btn-group + .btn-sm, .btn-group > .btn-sm {
    border-width: 1px;
}

#ruban-action-wizard {
    margin-left: 12px;
}

.ruban-boutons-explorateur {
    .btn {
        margin-bottom: 5px;
        @include breakpoint-max(sm) {
            width: calc((100% / 3) - 3px);
        }
        @include breakpoint-max-custom(700px) {
            width: calc((100% / 2) - 3px);
        }
        @include breakpoint-max(xs) {
            width: 100%;
        }
    }
}

.badge-sig-primary {
    @include style-compatible-print((
        background-color: $color-primary !important
    ));
}

.badge-sig-secondary {
    @include style-compatible-print((
        background-color: $color-secondary !important
    ));
}

.badge {
    .badge-text {
        display: none;
        position: absolute;
        z-index: 100;
        @include style-compatible-print((
            background-color: $color-black
        ));
        padding: 2px 5px;
        pointer-events: none;
        bottom: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        &:after {
            content: "";
            position: absolute;
            left: calc(50% - 5px);
            bottom: -5px;
            width: 10px;
            height: 10px;
            @include style-compatible-print((
                background-color: $color-black
            ));
            transform: rotate(45deg);
            z-index: -1;
        }
        @include breakpoint-max(lg) {
            left: -5px;
            transform: none;
        }
        &:after {
            @include breakpoint-max(lg) {
                left: 10px;
            }
        }
    }
    &.badge-w-text {
        &:hover, &:focus {
            .badge-text {
                display: block;
            }
        }
    }
}

.dropdown-menu{
    &.pull-right {
        left: initial;
        &.dropdown-caret {
            &:after {
                left: initial;
                right: 10px;
            }
            &:before {
                left: initial;
                right: 9px;
            }
        }
    }
}

.dropdown-sig-primary {
    > li, .dropdown-submenu {
        > a {
            border: 4px solid #ffffff00;
            &:hover, &:focus {
                background: $color-primary !important;
                border-color: $color-primary;
                color: $color-white;
            }
            &:active, &:active:focus {
                background: $color-primary-dark !important;
                border-color: $color-primary;
                color: $color-white;
            }
        }
        &.active {
            > a, > a:hover {
                background: $color-primary !important;
                border-color: $color-primary;
                color: $color-white;
            }
        }
    }
    .dropdown-submenu {
        &:hover {
            > a {
                background: $color-primary !important;
                border-color: $color-primary;
                color: $color-white;
            }
        }
    }
    @include breakpoint-max(lg) {
        &.pull-right {
            left: 5px;
            right: initial;
            &.dropdown-caret {
                &:after {
                    left: 10px;
                    right: initial;
                }
                &:before {
                    left: 9px;
                    right: initial;
                }
            }
        }
    }
    @include breakpoint-max(xs) {
        width: calc(100vw - 100px);
        &.dropdown-menu>li>a {
            white-space: initial;
        }
    }
}

.clearable {
    @include style-compatible-print((
        background: $color-white url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAABcSAAAXEgFnn9JSAAAAB3RJTUUH3wMTDQ0nMszejgAAAOtJREFUOMvdki1uwkEQxX/QBIGnpqpIkGCQaE7SnuC54nZuwHX+Hg0OGoJrj0ACNbPNZtj1hGcm8/L27XzB88LMcnwBSClRxsxnXUavYvQOHIGppH3BT4AdMJb0Xb7pB4M34ODpzszmzs/dAODgurqJ52V1WzP7Arah+n7VxMyQdAaWwXgd8qWkczmXfxNJpJSQ1AEr4BIeX4CVpM51zXYyfoBr4K7O36EX2im30MJU0j7r71ZsZqPKbxvgI3Cvkn5b7QyBWxjiZxj2zXXN7ZyAmVMLSZ3zHbBwfibpFK+2dvaDxtkPamf/OPgDVfRoowG2LpYAAAAASUVORK5CYII=') no-repeat right -25px center
    ));
    border: 1px solid #999;
    padding: 3px 18px 3px 4px;
    border-radius: 3px;
    transition: background 0.4s;
    &.x {
        background-position: right 5px center;
    }
    &.onX {
        cursor: pointer;
    }
}

.close-message {
    float: right;
    font-size: $font-size-content-lg;
    font-weight: 700;
    color: $color-black;
    text-shadow: 0 1px 0 $color-white;
    opacity: 0.2;
    border: 0 none;
    padding: 0;
    cursor: pointer;
    background: none repeat scroll 0 0 transparent;
    &:hover, &:focus {
        color: $color-black;
        text-decoration: none;
        cursor: pointer;
        opacity: 0.5;
    }
}

.widget-toolbar {
    @include breakpoint-max(lg) {
        &.no-border {
            float: left;
            padding: 0;
        }
    }
}

.batiment-entete-bootstrap {
    display: inline-block;
    width: 150px;
}

div {
    .childSlider {
        display: none;
        margin-left: 26px;
    }

}

.repere,
.row-repere {
    display: -webkit-flex; /* Safari */
    display: flex;
}

.cell-repere {
    border-color: #a11506;
    border-style: solid;
    border-left-style: hidden;
    border-bottom-style: hidden;
    border-width: thin;
    min-height: 60px;
    -webkit-flex: auto; /* Safari 6.1+ */
    -ms-flex: auto; /* IE 10 */
    flex: auto;
}

.cell-resultat {
    min-height: 60px;
    flex: auto;
}

.liens-reperes {
    .lien.active {
        font-weight: bold;
        @include style-compatible-print((
            background-color: #eef4f9
        ));
    }
    a:focus {
        @include style-compatible-print((
            color: #0050b2
        ));
        text-decoration: None;
    }
}

.row-resultat {
    border-color: #a11506;
    border-style: solid;
    border-left-style: hidden;
    border-bottom-style: hidden;
    border-right-style: hidden;
    border-width: thin;
}

.cell-nom-repere {
    @include style-compatible-print((
        background-color: #fae3ba
    ));
    font-weight: bold;
    &.first {
        border-left-style: solid;
    }
}

.cell-resultat.resultat {
    flex: auto;
    width: 6.5%;
}

.repere-col-first {
    padding-left: 0;
}

.repere-header-col-first {
    flex: auto;
}

.cell-repere {
    &.choix-classe {
        border: thick solid $color-white;
    }
    &.selected {
        @include style-compatible-print((
            background-color: #c2fac1
        ));
    }
    &.click {
        cursor: pointer;
    }
}

div[disabled].cell-repere {
    cursor: not-allowed;
    &.cell-repere-disabled {
        @include style-compatible-print((
            background-color: #c0c0c0 !important
        ));
    }
}

.repere-header {
    @include style-compatible-print((
        background-color: #a11506,
        color: $color-white
    ));
    text-align: center;
    font-weight: bold;
    border: thick solid $color-white;
    flex: auto;
}

.titre-resultat {
    text-align: right;
    font-weight: bold;
}

.zoom-contenu-bouton.droite {
    margin-left: 7px;
    float: right;
}

.contenu-zoom {
    @include style-compatible-print((
        background:  $color-grey-light-ace
    ));
}

.zoom {
    z-index: 10000;
    width: 100%;
    height: 100%;
}

.zoom-hover:hover {
    transform: scale(1.01);
}

.drop-shadow-arrondi {
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    border-radius: 12px !important;
    &:hover {
        -moz-box-shadow: 3px 3px 4px #b1b1b1 !important;
        -webkit-box-shadow: 3px 3px 4px #b1b1b1 !important;
        box-shadow: 3px 3px 4px #b1b1b1 !important;
    }
}

%styles-entete {
    margin: auto;
    z-index: 1000;
    @include style-compatible-print((
        background-color: $color-white
    ));
}

#entete-fixe {
    @extend %styles-entete;
    position: fixed;
    top: 0;
    width: 100%;
}

#entete-fixe-propagation {
    @extend %styles-entete;
    position: fixed;
    width: 100%;
}

#entete-fixe-reperes {
    @extend %styles-entete;
}

.flash-message {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    .panel-group {
        margin-bottom: 0;
    }
}

.steps {
    li {
        &.complete {
            .step {
                cursor: pointer;
            }
        }
        &:before {
            right: 50%;
            @include style-compatible-print((
                box-shadow: 0 0 0 1px #C2C1C1 !important
            ));
        }
        .title {
            font-size: 0.85em;
        }
        &.active {
            .step {
                -moz-transform: scale(1.1);
                -webkit-transform: scale(1.1);
                -o-transform: scale(1.1);
                -ms-transform: scale(1.1);
                transform: scale(1.1);
                border-color: $color-green;
                @include style-compatible-print((
                    box-shadow: 0 0 0 2px $color-blue !important
                ));
                position: relative;
            }
            .title {
                font-weight: bold;
            }
            &:before {
                border-color: $color-blue;
                right: 50%;
            }
        }
        &:only-child {
            &:before {
                display: none;
            }
        }
        &:last-child:not(:first-child) {
            &:before {
                max-width: 100%;
                width: 100%;
                right: 50%;
            }
        }
    }
}

.certificat-diff-fleche {
    font-size: 13px;
    margin-top: 5px;
    margin-left: -4px;
    @include style-compatible-print((
        color: $color-red-light
    ));
}

.list-group-item {
    &:last-child {
        margin-bottom: -1px;
    }
}

.div-list-group-item {
    display: flex;
    align-items: center;
    padding: 0;
    .div-list-group-item-content {
        flex-grow: 1;
        padding: 10px $spacing-sm;
    }
    .div-list-group-item-actions {
        padding: 10px $spacing-sm 10px 0;
    }
    .div-list-group-item-btn {
        margin: 0 !important;
    }
    &:hover, &:focus {
        text-decoration: none;
        color: #555;
        background-color: #f5f5f5;
    }
}

.liste-nature-over:hover {
    background-color: #f5f5f5;
}

.liste-over:hover {
    background-color: #f5f5f5;
}

.drop-down-item {
    @include reset-button-style;
    width: 100%;
    @include style-compatible-print((
        background-color: $color-white
    ));
    text-align: left;
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        background-color: $color-blue;
    }
}

/* toasts */
#messages {
    border-radius: 6px;
    z-index: 9999;
    border-width: 1px;
    box-shadow: 0 2px 2px $color-grey-ace;
    line-height: 25px;
    font-size: $font-size-content-md;
    top: -5px;
    padding-bottom: 7px;
    padding-top: 7px;
    visibility: hidden;
    position: fixed;
    background: #fefff4; /* Old browsers */
    background: -moz-linear-gradient(top, #fefff4 0%, #e9eddc 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fefff4), color-stop(100%, #e9eddc)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #fefff4 0%, #e9eddc 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #fefff4 0%, #e9eddc 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #fefff4 0%, #e9eddc 100%); /* IE10+ */
    background: linear-gradient(to bottom, #fefff4 0%, #e9eddc 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fefff4', endColorstr='#e9eddc', GradientType=0); /* IE6-9 */
    p {
        font-size: $font-size-title-sm;
    }
    > span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
    }
}

#message-toast {
    -webkit-box-shadow: 0 0 12px rgba(255, 255, 255, 0.51);
    -moz-box-shadow: 0 0 12px rgba(255, 255, 255, 0.51);
    box-shadow: 0 0 12px rgba(255, 255, 255, 0.51);
    border-radius: 15px;
    bottom: 100px;
    color: $color-white;
    font-size: $font-size-title-md;
    left: 0;
    line-height: 22px;
    margin: auto;
    max-width: 500px;
    min-width: 100px;
    padding: 20px;
    position: fixed;
    right: 0;
    text-align: center;
    width: 90%;
    z-index: 1000000;
}

ul.nav-tabs li.active i.ace-icon {
    @include style-compatible-print((
        color: #478fca
    ));
}

ul.nav-tabs li:not(active) i.ace-icon {
    @include style-compatible-print((
        color: #bbb
    ));
}

.paginate_button {
    padding: 0 !important;
}

.pagination > li > a {
    margin: 0;
}

.sous-total {
    text-decoration: underline !important;
    font-weight: bold;
}

.total {
    text-decoration: underline !important;
    border-bottom: 1.5px solid $color-black !important;
    font-weight: bold;
}

#spinorama {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 90000000;
    &:empty {
        display: none;
    }
}

.evenements-multiples-fleche {
    @include style-compatible-print((
        background-color: #D5D5D5
    ));
}

.tooltip {
    position: fixed;
}

/*Optimisation de la couleur des tooltips de Bootstrap pour GeoCentralis*/
.tooltip-inner {
    @include style-compatible-print((
        background-color: #838383
    ));
}

.ui-autocomplete {
    position: absolute;
    cursor: default;
    z-index: 1000000 !important;
}

%information-animated {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: $spacing-sm;
    min-height: 300px;
    width: 100%;
    transition: transform 450ms ease, opacity 450ms ease;
    &.hiding {
        opacity: 0;
        transform: translateX(10px);
    }
    .information-animated-text {
        @include style-compatible-print((
            color: $color-grey-dark
        ));
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        width: 360px;
        transform: translateY(-40px);
        transition: transform $time-lg ease;
    }
    .information-animated-btn {
        transform: translateY(-40px);
        transition: transform $time-lg ease;
    }
    .information-animated-icon {
        width: 220px;
        opacity: 0;
        margin-bottom: 20px;
        transform: scale(.5);
        transition: transform $time-lg ease, opacity $time-lg ease;
    }
    &.loaded {
        .information-animated-text, .information-animated-btn {
            transform: translateY(0);
        }
        .information-animated-icon {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.information-animated-alt {
    @extend %information-animated;
    position: static;
    min-height: 0;
    .information-animated-icon {
        font-size: 120px;
        width: auto;
        color: $color-grey-dark;
        margin-bottom: 0;
    }
}

.information-animated-sig {
    @extend %information-animated;
    min-height: 400px;
}

.information-animated-explo {
    @extend %information-animated;
    position: sticky;
    @include breakpoint-min(md) {
        .contenu-explo {
            position: absolute;
        }
    }
}

.widget-main-alt {
    padding-right: 24px;
}

.widget-main {
    .exp-recherche {
        padding-bottom: 12px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 0;
        padding-left-ltr-source: physical;
        padding-left-rtl-source: physical;
        padding-right-ltr-source: physical;
        padding-right-rtl-source: physical;
    }
}

.wizard-accordion-sub-title {
    @include style-compatible-print((
        color: #4c8fbd,
        background-color: #eef4f9
    ));
    position: relative;
    font-weight: 700;
    font-size: 13px;
    padding: 8px;
    display: block;
    margin-top: 10px;
}

.wizard-odd {
    background-color: #edf2f6;
}

.wizard-even {
    background-color: #dcedf9;
}

.wizard-autre-evenement {
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    @include style-compatible-print((
        background-color: #fffac4,
        color: #8a6d3b
    ));
    border-color: #D8D045;
    .lien-evenement {
        padding: 5px;
        margin-left: 10px;
        display: inline-block;
    }
}

.wizard-executer-propagation {
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-color: #D8D045;
    @include style-compatible-print((
        background-color: #fffac4,
        color: #8a6d3b
    ));
}

.banniere-propagation-succes {
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    @include style-compatible-print((
        background-color: #87b87f!important
    ));
}

.affix-bottom {
    top: 0 !important;
}

.align-bottom-evb-parent {
    position: relative;
}

.align-bottom-evb {
    position: absolute;
    bottom: 3px;
}

.stuck { /* Utilisé pour waypoint */
    position: fixed;
    z-index: 8000;
    top: 45px; /* Hauteur de navbar navbar-default navbar-fixed-top de index.html */
}

.ui-slider-handle-custom {
    width: 42px !important;
    height: 22px !important;
    top: 50% !important;
    transform: translateY(-50%);
    text-align: center;
    line-height: 1.6em;
    &:before, &:after {
        display: none !important;
    }
}

.slimScrollBar {
    display: block !important;
}

.ace-thumbnails {
    > li {
        border: 0;
    }
    .ace-thumbnail-single {
        float: none;
        overflow: visible;
    }
}

/* changer la hauteur initiale de ace: sert à voir le texte sur plusieurs lignes dans un tree view*/
.tree {
    .tree-item {
        height: auto;
    }
}

.gauge {
    margin: 10px 10px;
}

.fiche-residentiel i.display-id {
    color: $color-black;
}

.display-id {
    position: absolute;
    z-index: 999;
    left: -10px;
    margin-top: 10px;
}

.white-popup-block {
    background: $color-white;
    padding: 20px 30px;
    text-align: left;
    max-width: 650px;
    margin: 40px auto;
    position: relative;
}

#custom-content-popup {
    img {
        max-width: 100%;
        margin-bottom: 10px;
    }
}

#pageSpinner {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1000;
    top: 0;
}

#page-spinner-copy {
    color: $color-black;
    margin-top: 180px;
    font-size: $font-size-title-xl;
}

.ace-spinner-width-100 .ace-spinner {
    width: 100% !important;
}

.ace-spinner {
    max-width: 100%;
}

.nb_regroupement_popover {
    > .popover {
        z-index: 123456;
    }
}

/* pour le scroll du content */
#tab-sommaire {
    height: auto;
    position: relative;
}

.tabs-responsive {
    display: flex;
    @include breakpoint-max(sm) {
        flex-direction: column;
        &.nav-tabs {
            > li.active {
                 > a, > a:focus, > a:hover {
                    border-top: 1px solid #c5d0dc;
                    border-left: 4px solid #4c8fbd;
                    border-bottom: 1px solid #c5d0dc;
                 }
            }
        }
    }
}

.search-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 35px;
    padding-bottom: 10px;
    .search-header-left {
        margin-right: $spacing-sm;
    }
    .search-header-right {
        .btn {
            margin-bottom: 0 !important;
        }
    }
    @include breakpoint-max(md) {
        flex-direction: column-reverse;
        align-items: flex-start;
        .search-header-left {
            margin-right: 0;
        }
        .search-header-right {
            align-self: flex-end;
            margin-bottom: 5px;
        }
    }
    @include breakpoint-max(sm) {
        flex-direction: row;
        align-items: center;
        .search-header-left {
            margin-right: $spacing-sm;
        }
        .search-header-right {
            margin-bottom: 0;
        }
    }
    @include breakpoint-max(xs) {
        flex-direction: column-reverse;
        align-items: flex-start;
        .search-header-left {
            margin-right: 0;
        }
        .search-header-right {
            margin-bottom: 5px;
        }
    }
}

.widget-header {
    @include print-mode {
        background: #f7f7f7 repeat-x !important;
        background-image: -webkit-linear-gradient(top, #fff 0, #eee 100%) !important;
        background-image: -o-linear-gradient(top, #fff 0, #eee 100%) !important;
        background-image: linear-gradient(to bottom, #fff 0, #eee 100%) !important;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffeeeeee', GradientType=0) !important;
    }
}

.widget-header-resp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 6px;
    &:before, &:after {
        display: none;
    }
    .widget-header-left, .widget-header-right {
        float: none;
        .btn {
            margin-bottom: 0 !important;
        }
    }
    .widget-header-left {
        margin-right: $spacing-sm;
    }
    @include breakpoint-max(xs) {
        flex-direction: column-reverse;
        align-items: flex-start;
        .widget-header-left {
            margin-right: 0;
        }
        .widget-header-right {
            align-self: flex-end;
            margin-bottom: 8px;
        }
    }
}

/* responsive widget alt - for table/collapse header w arrows */
.widget-header-alt {
    display: flex;
    padding: 0;
    .widget-header-alt-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        flex-shrink: 1;
        line-height: 1.2;
        padding: 8px $spacing-sm;
    }
    .widget-header-alt-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

/* fake ol (number list) responsive styling */
.nbr-list-resp {
    position: relative;
    padding-left: 48px;
    .nbr-list-nbr {
        position: absolute;
        left: 0;
        margin-right: 0;
    }
    @include breakpoint-max(xs) {
        padding-left: 35px;
    }
}

.sectionContainer-resp {
    .sectionContainer-col-container {
        display: flex;
        justify-content: space-between;
        .sectionContainer-col-left, .sectionContainer-col-right {
            float: none;
            width: calc(50% - 7px);
        }
    }
    @include breakpoint-max(md) {
        .sectionContainer-col-container {
            flex-direction: column;
            .sectionContainer-col-left, .sectionContainer-col-right {
                width: 100%;
            }
            .sectionContainer-col-left {
                margin-bottom: $spacing-sm;
            }
        }
    }
}

.lineContainer-resp {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    .left_fp, .right_fp {
        float: none;
        margin-bottom: 0;
    }
    .right_fp {
        padding-left: $spacing-sm;
    }
    @include breakpoint-max(sm) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: $spacing-sm;
        .left_fp, .right_fp, .plusLarge, .plusMince {
            width: 100%;
        }
        .left_fp {
            margin-bottom: 4px;
        }
        .right_fp {
            padding-left: 0;
        }
    }
}

.row-info-number {
    .col-number {
        width: 125px;
    }
}

.bloc-historique {
    overflow-x: hidden;
}

#ef-date-evenement-historik {
    list-style-type: none;
    width: 185px;
    margin: 0;
}

.bloc-historique-fond-barre {
    @include style-compatible-print((
        background-image: repeating-linear-gradient( -45deg, transparent 0 20px, #ffffff55  20px 40px ) !important
    ));
}

.barre{
    text-decoration: line-through !important;
}

.modal-animated-fade {
    .modal-animated-fade-item {
        transition: transform 450ms ease, opacity 450ms ease;
        opacity: 0;
        transform: translateX(-10px);
        @include transition-delay-childs(7, 100ms, (opacity, transform));
        &.visible {
            opacity: 1;
            transform: translateX(0);
        }
        &.btn {
            transition: transform 450ms ease, opacity 450ms ease, border-color ease .15s, background-color ease .15s;
        }
    }
    @include breakpoint-max(sm) {
        .modal-animated-fade-item {
            &.btn {
                width: 100%;
                margin: 0 0 5px 0 !important;
                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
}

/* #18773 - modal-print-animated - animations customs - non réutilisables (pour l'instant) */
.print-animated {
    @extend .modal-animated-fade;
    .print-animated-body {
        /* animation d'ouverture et fermeture du body du modal */
        transition: height $time-lg ease;
        position: relative;
        .print-animated-title {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            padding: $spacing-sm;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
    .print-animated-content {
        @extend .modal-animated-fade-item;
        &.removing {
            opacity: 0;
            transform: translateX(10px);
        }
    }
    .print-animated-startbtn {
        .print-animated-startbtn-icon:before {
            content: "\f02f";
        }
        .print-animated-startbtn-icon-container {
            display: inline-block;
            transition: transform $time-sm ease;
        }
        &:active, &:active:focus {
            .print-animated-startbtn-icon-container {
                transform: scale(0.2);
            }
        }
        &.loading {
            .print-animated-startbtn-icon {
                animation: rotation $time-xl linear infinite;
                &:before {
                    content: "\f110";
                }
            }
        }
    }
    @include breakpoint-max(sm) {
        .print-animated-content {
            &.btn {
                width: 100%;
                margin: 0 0 5px 0 !important;
                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
}

.legend-box {
    border: 1px solid $color-grey;
    padding: $spacing-sm;
}

.legend-item{
    display: flex;
    align-items: center;
}

li.legend-item:not(:last-child) {
    margin-bottom: 10px;
}

.legend-square {
    height: 20px;
    width: 40px;
    border: 1px solid $color-black;
    margin-right: $spacing-sm;
}

.alert-w-icon {
    display: flex;
    align-items: center;
    .alert-icon {
        flex-grow: 0;
        flex-shrink: 0;
        &:first-child {
            margin-right: $spacing-sm;
        }
        &:last-child {
            margin-left: $spacing-sm;
        }
    }
}

.signature-bloc {
    display: flex;
    align-items: flex-end;
    width: 100%;
    .signature-text {
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 15px;
        margin-bottom: 0;
    }
    .signature-line {
        flex-grow: 1;
        flex-shrink: 1;
        border-bottom: 1px solid $color-black;
    }
    @include breakpoint-max(md, true) {
        flex-direction: column;
        align-items: flex-start;
        .signature-text {
            margin-right: 0;
            margin-bottom: 15px;
            width: 100%;
        }
        .signature-line {
            width: 100%;
        }
    }
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.signature-print {
    @include print-mode {
        page-break-inside: avoid;
        padding: 40px 15px 25px 15px;
        background-color: $color-white !important;
        border: 1px solid $color-black;
    }
}

.private-media {
    pointer-events: none;
    @media print {
        display: none;
    }
}

.row-striped {
    padding-top: 5px;
    padding-bottom: 5px;
    &:nth-child(even) {
        @include style-compatible-print((
            background-color: $color-grey-lighter-ace
        ));
    }
}

.sous-bloc-form-sortable {
    position: relative;
    left: 0 !important;
    background-color: $color-grey-light-ace;
    padding-left: 40px;
    .sortable-handle-logo {
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2px;
        row-gap: 2px;
        width: 35px;
        padding: 12px;
        border: 1px solid $color-grey;
        .sortable-handle-logo-box {
            width: 4px;
            height: 4px;
            background-color: $color-grey-darker;
        }
    }
    &.nouvelle-ligne {
        .sortable-handle-logo {
            display: none;
        }
    }
}

.text-separator {
    display: flex;
    align-items: center;
    text-align: center;
    .text-separator-line {
        flex-grow: 1;
        border-bottom: 1px solid $color-grey;
    }
}

/* link-underline */
.link-underline {
    color: $color-white;
    position: relative;
    padding-bottom: 2px;
    text-decoration: none !important;
    transition: opacity $time-sm ease;
    &:after {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 0;
        background-color: $color-white;
        transition: width $time-sm ease;
    }
    &.link-underline-featured {
        &:after {
            background-color: $color-secondary;
        }
    }
    &:hover, &:focus {
        color: $color-white;
    }
    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &.active {
        &:after {
            width: 100%;
        }
    }
    &:active,
    &:active:focus {
        &:after {
            opacity: .8;
        }
    }
}

.border-radius-sm {
    border-radius: 4px;
}

.splitinput-container {
    display: flex;
    margin: -4px;
    .splitinput {
        margin: 4px;
        max-width: 35px;
        text-transform: uppercase;
    }
}

/* titre custom avec ligne à gauche */
.title-line {
    position: relative;
    padding-left: 20px;
    &:before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 5px;
        height: 100%;
        background-color: $color-secondary;
    }
}

.icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    .icon-text-icon-container {
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 5px solid $color-blue;
        margin-bottom: 20px;
        transform: scale(0);
        opacity: 0;
        transition: transform $time-lg ease, opacity $time-lg ease;
        &.loaded {
            transform: scale(1);
            opacity: 1;
        }
        .icon-text-icon {
            font-size: 40px;
        }
    }
    @include breakpoint-min(xs) {
        flex-direction: row;
        text-align: left;
        .icon-text-icon-container {
            flex-grow: 0;
            flex-shrink: 0;
            margin-bottom: 0;
            margin-right: 20px;
        }
        .icon-text-text-container {
            flex-grow: 1;
            flex-shrink: 1;
        }
    }
}
