/* styles propres aux pages d'erreur (ex: 403, 404) */
.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px 15px;
    min-height: 100vh;
    .error-geocentralis-logo {
        width: 375px;
        max-width: 100%;
    }
    .error-content {
        border: 6px solid change-color($color-primary, $lightness: 42%, $saturation: 16%);
        background-color: $color-white;
        padding: 30px 15px;
        width: 700px;
        max-width: 100%;
    }
    .error-title {
        .error-title-number {
            font-size: 100px;
            font-weight: 700;
            .number {
                display: inline-block;
                @include animation-load(3);
            }
        }
    }
    @include breakpoint-min(md) {
        .error-title {
            .error-title-number {
                font-size: 130px;
            }
        }
    }
}
