.choisir-permis,
.choisir-mutation {
    &:hover {
        color: #5CB85C;
        cursor: pointer;
    }
}

#s2id_exp-ue-liste-ville {
    @include breakpoint-max(lg) {
        padding-bottom: 5px;
    }
}

::-webkit-scrollbar {
    @include breakpoint-max(lg) {
        width: 10px !important;
        height: 12px !important;
    }
}

::-webkit-scrollbar-thumb {
    @include breakpoint-max(lg) {
        background: #C1C1C1;
        &:hover {
            background: #787878;
        }
    }
}

.geocentralis-logo {
    height: 45px;
}

img {
    &.responsive {
        max-width: 100%;
    }
}

.page-header {
    margin-top: 10px;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: 0.5em;
    padding-bottom: 0.5em;
    padding-top: 0;
    h1 {
        margin-left: 0 !important;
        color: $color-primary;
    }
}

#page_content {
    padding: 5px $spacing-sm 0;
}

.profile-info-name-geoc {
    width: 200px !important;
}


.panel-heading-37h{
  height: 37px;
}

.panel-title-float-left{
  float: left;
}
