.navbar-fixed-top + .main-container {
    padding-top: 65px;
}

/* navbar overlay fix */
.navbar-fixed-top + #main-container {
    padding-top: 0;
}

.navbar {
    &.geocentralis {
        background-color: $color-primary !important;
    }
    &.geocentralis-debug-prod {
        background-color: red !important;
    }
    .navbar-alt {
        .navbar-toggle {
            position: relative;
            display: none;
            .icon-bar {
                position: absolute;
                left: 0;
                transition: opacity $time-md ease, transform $time-md ease, top $time-md ease;
                &:nth-child(2) {
                    top: 10px;
                }
                &:nth-child(3) {
                    top: 12px;
                }
                &:nth-child(4) {
                    top: 18px;
                }
            }
            &.display {
                .icon-bar {
                    &:nth-child(2) {
                        top: 16px;
                        transform: rotate(45deg);
                    }
                    &:nth-child(3) {
                        opacity: 0;
                    }
                    &:nth-child(4) {
                        top: 12px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    @include breakpoint-max(md) {
        .navbar-alt {
            .navbar-toggle {
                display: inline-block;
            }
        }
    }
    @include breakpoint-max(sm) {
        &:not(.navbar-collapse) {
            .navbar-alt {
                .ace-nav {
                    > li {
                        :last-child, :first-child {
                            border: none;
                        }
                    }
                }
            }
        }
    }
}

.navbar-alt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .navbar-alt-left, .navbar-alt-right {
        display: flex;
        align-items: center;
    }
    .ace-nav {
        > li > a.dropdown-toggle {
            background: none;
        }
    }
    @include breakpoint-max(md) {
        .user-info {
            display: none;
        }

    }
    @include breakpoint-max(sm) {
        .dropdown-menu > li > a {
            font-size: $font-size-content-lg;
            padding: 8px 16px;
        }
        .bottin-outter {
            margin-right: 0;
        }
        .bottin-select {
            .bottin-select-txt {
                display: none;
            }
        }
    }
    @include breakpoint-max(xs) {
        .navbar-brand {
            .navbar-brand-txt:not(.navbar-brand-txt-info) {
                display: none;
            }
            .navbar-brand-txt-info {
                font-size: $font-size-content-md;
            }
            .geocentralis-logo {
                width: 110px;
            }
        }
    }
}
.navbar-fixed-container {
    position: relative;
    z-index: 1999;
}

.navbar-buttons.navbar-header.pull-right.geocentralis {
    vertical-align: middle;
    padding-top: 12px;
    li.geocentralis-blue > a {
        background-color: $color-primary !important;
    }
}

.select2-drop.bigdrop {
    @include breakpoint-max(xs) {
        left: 15px !important;
        width: calc(100vw - 30px) !important;
    }
}

.dropdown-user {
    display: flex;
    align-items: center;
    color: $color-white !important;
    text-decoration: none !important;
    padding: 5px 10px;
    border-radius: 14px;
    background-color: #00000000;
    transition: background-color $time-md ease;
    .dropdown-user-icon {
        font-size: 30px;
        margin-right: 8px;
    }
    &:hover, &:focus, &:active {
        background-color: $color-primary-dark;
    }
}

.bottin {
    .select2-choice {
        display: flex;
        align-items: center;
        background-color: $color-white;
        background-image: none !important;
        padding: 5px 10px;
        border: none;
        border-radius: 14px;
        min-height: 40px;
        .select2-arrow {
            right: 5px;
            top: 4px;
        }
    }
    .bottin-select {
        display: flex;
        align-items: center;
        .fa-phone {
            font-size: 20px;
        }
    }
}

.bottin-ligne {
    font-size: 9pt;
    &:first-child {
        font-size: 14pt;
        font-weight: bold;
    }
    &:empty {
        display: none;
    }
}

.bottin-select{
    color: $color-grey-ace;
}

.user-menu {
    .user-menu-info {
        /* styles repris d'ace, à l'exception de la gestion de l'overflow */
        font-size: 13px;
        padding-left: 11px;
        padding-right: 11px;
        margin-bottom: 1px;
        margin-top: 1px;
        max-width: 250px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
