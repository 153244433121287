.managment-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .managment-topbar-left, .managment-topbar-right {
        display: flex;
        align-items: center;
        padding: 6px $spacing-sm;
    }
    .managment-topbar-left {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .managment-topbar-right {
        flex-grow: 1;
        flex-shrink: 1;
        justify-content: flex-end;
    }
    #gestion-semaine {
        width: 580px;
        margin: 0;
        > div > div label {
            text-align: left;
            padding-top: 7px;
            margin-bottom: 0;
        }
    }
    .form-group {
        margin: 0;
    }
    .btn {
        margin-bottom: 0 !important;
    }
    .managment-topbar-btn-container {
        display: flex;
        margin: -5px;
        .managment-topbar-btn {
            margin: 5px !important;
        }
    }
    .managment-topbar-item {
        h5 {
            margin: 0;
        }
        #etat_feuille_temps {
            margin-bottom: 0;
        }
    }
    @include breakpoint-max-custom(1415px) {
        .managment-topbar-btn-container {
            .managment-topbar-btn {
                .btn-text {
                    display: none;
                }
            }
        }
    }
    @include breakpoint-max(lg) {
        flex-direction: column;
        .managment-topbar-left, .managment-topbar-right {
            justify-content: flex-start;
            width: 100%;
        }
        .managment-topbar-btn-container {
            width: 100%;
            .managment-topbar-btn {
                flex: 1;
                .btn-text {
                    display: inline;
                }
            }
        }
    }
    @include breakpoint-max(md) {
        .managment-topbar-left {
            flex-direction: column;
            align-items: flex-start;
            .managment-topbar-item:not(:last-child) {
                margin-bottom: 8px;
            }
        }
        #gestion-semaine {
            width: 100%;
        }
    }
    @include breakpoint-max(sm) {
        .managment-topbar-right {
            padding-top: 0;
        }
    }
    @include breakpoint-max(xs) {
        .managment-topbar-btn-container {
            .managment-topbar-btn {
                .btn-text {
                    display: none;
                }
            }
        }
        .managment-topbar-item {
            width: 100%;
        }
    }
}
