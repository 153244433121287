.datatable {
    th {
        div {
            &.dataTables_sizing {
                font-size: $font-size-content-sm;
            }
        }
    }
}

div {
    &.dt-buttons {
        float: none;
    }
}

#batiment-x-unite-evaluation-sous-bloc-wizard {
    .dataTables_wrapper {
        .sous-bloc-form {
            &:first-child, &:last-child {
                background-color: transparent;
                border-bottom: none;
            }
            .row {
                &:first-child, &:last-child {
                    background-color: transparent;
                    border-bottom: none;
                }
            }
        }
    }
}

.dataTables_wrapper {
    .row {
        &:first-child {
            background-color: $color-white;
            padding-bottom: 5px;
        }
        &.margin-top-1 { /* écrase ace */
            @extend .margin-top-1;
        }
    }
    .col-sm-12 {
        padding-left: 0;
        padding-right: 0;
    }
    label {
        font-size: $font-size-content-md;
    }
}

.dataTables_info {
    font-size: 10pt;
}

.DTFC_LeftBodyWrapper {
    top: 0 !important;
}

/*
 * Sort arrow icon positioning
 */
table.display thead th div.DataTables_sort_wrapper {
    position: relative;
    padding-right: 20px;
    span {
        position: absolute;
        top: 50%;
        margin-top: -8px;
        right: 0;
    }
}

.divTable-fake {
    .header {
        line-height: 2.5em;
        margin-right: 0;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        color: #707070;
        border-left: 1px solid $color-grey-light;
        border-right: 1px solid $color-grey-light;
        border-top: 1px solid $color-grey-light;
        font-weight: 700;
        background: #F2F2F2 repeat-x;
        background-image: -webkit-linear-gradient(top, #f8f8f8 0, #ececec 100%);
        background-image: -o-linear-gradient(top, #f8f8f8 0, #ececec 100%);
        background-image: linear-gradient(to bottom, #f8f8f8 0, #ececec 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff8f8f8', endColorstr='#ffececec', GradientType=0);
        th {
            border-right: 1px solid $color-grey-light;
            border-bottom: 1px solid $color-grey-light;
            margin: 0;
            padding: 8px;
            line-height: 1.42857143;
            vertical-align: top;
        }
    }
    .body {
       line-height: 2.5em;
        margin-right: 0;
        margin-left: 0;
        margin-top: 0;
        padding-bottom: 0;
        color: #707070;
        border-left: 1px solid $color-grey-light;
        border-top: 1px solid $color-grey-light;
        border-right: 1px solid $color-grey-light;

    }
    > .body {
        &:last-child {
           border-bottom: 0 solid $color-grey-light;
        }
        .tr {
            .td {
                border-right: 1px solid $color-grey-light;
                border-bottom: 1px solid $color-grey-light;
                margin: 0;
                padding: 8px;
                line-height: 1.42857143;
                vertical-align: top;
                input {
                    border: 0;
                }
                &.padding-0 {
                    padding: 0;
                }
                &:last-child {
                    border-right: 0 solid $color-grey-light;
                }
            }
            &.row {
               margin: 0;
            }
        }
        > div {
            > .tr {
                .td {
                    input {
                        border: 0;
                    }
                }
            }
        }
    }
    .div-header-suppression {
        padding-left: 12px;
    }
    .header-suppression {
        margin-left: $spacing-sm;
        margin-right: 0;
    }
    &.row {
        &.champs-multiple {
            margin-left: -5px;
            margin-right: 0;
        }
    }
    tr {
        &:hover {
            background-color: #f5f5f5;
        }
        &.odd {
            background-color: $color-grey-lighter;
        }
        &.even {
            background-color: $color-white;
        }
    }
}

.div-ctrl-suppression {
    padding-left: 12px;
}

.for-ctrl-suppression {
    margin-left: $spacing-sm;
    margin-right: 0;
}

.champs-multiple .divTable-fake label.for-ctrl-suppression > i {
    left: 20px;
}


/* tableflex - simple responsive tables - used in form méthode revenu */
/* tableflex general */
.tableflex-detached {
    border-top: 2px solid $color-grey-light;
}

.tableflex {
    .tableflex-row {
        border: 1px solid $color-grey-light;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        .tableflex-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-grow: 0;
            flex-shrink: 0;
            padding: 2px;
            word-break: break-word;
            hyphens: auto;
            position: relative;
            &:not(:last-child) {
                border-right: 1px solid $color-grey-light;
            }
            &.suppression-ligne:before {
                top: initial;
            }
        }
    }
    .tableflex-head {
        font-weight: 700;
        .tableflex-row {
            background-color: #e7e7e7;
        }
    }
    .tableflex-head-mobile {
        display: none;
        font-weight: 700;
        margin-bottom: 5px;
    }
    .tableflex-body {
        .tableflex-row {
            border-top: none;
            background-color: $color-white;
            &:nth-child(even) {
                background-color: $color-grey-lighter;
            }
            &:hover, &:focus {
                background-color: #f5f5f5 !important;
            }
        }
    }
    .tableflex-footer {
        .tableflex-row {
            border: none;
            background-color: $color-white;
            .tableflex-col {
                border-right: 1px solid $color-grey-light;
                border-bottom: 1px solid $color-grey-light;
                &:first-child {
                    border-left: 1px solid $color-grey-light;
                }
            }
            &:first-child {
                .tableflex-col {
                    border-top: 1px solid $color-grey-light;
                }
            }
        }
    }
    .tableflex-input-erreur {
        font-size: $font-size-content-sm;
        line-height: 1.2;
        margin-top: 3px;
        margin-bottom: 0;
        &:empty {
            display: none;
        }
    }
    @include breakpoint-max(md) {
        .tableflex-row {
            flex-wrap: wrap;
            .tableflex-col {
                justify-content: space-between;
                border-top: 1px solid $color-grey-light;
                border-right: 1px solid $color-grey-light;
                &:first-child {
                    border-top: none;
                    border-right: none;
                }
                &.suppression-ligne:before {
                    bottom: 15px;
                }
            }
            .tableflex-col-1 {
                &:nth-child(4n+4) {
                    border-right: none;
                }
            }
        }
        .tableflex-head {
            display: none;
        }
        .tableflex-head-mobile {
            display: block;
            text-align: left;
        }
    }
    @include breakpoint-max(sm) {
        .tableflex-row {
            .tableflex-col {
                border: none !important;
                &:not(:last-child) {
                    border-bottom: 1px solid $color-grey-light !important;
                }
            }
        }
    }
}

.hyperlien-tableau-operation-ue {
    @include reset-button-style;
    color: $color-primary;
    text-decoration: underline;
}

/* Utilisé uniquement pour garder un espace entre l'accordéon et le prochain contenu */
.dataTables_length,
.action-buttons-text > i {
    padding-top: 12px;
}

.table-filtres {
    overflow-x: auto;
    > table {
        margin-bottom: 0 !important;
    }
}

.filtres-actions {
    padding-top: 5px;
}

.ruban-action-datatables {
    position: relative;
    padding-left: 25px;
}

.datatable_alt_container {
    .dataTables_wrapper {
        .row {
            padding: 0;
        }
    }
    .dataTables_length,
    .dataTables_filter,
    .dataTables_paginate {
        padding: 12px 0;
    }
}

table {
    &.datatable {
        tbody {
            td {
                &.no-padding {
                    padding: 0;
                }
            }
        }
    }
    &.dataTable {
        &.dtr-inline {
            &.collapsed {
                tbody {
                    tr.parent {
                        td, th {
                            &:first-child:before {
                                /* Datatables : Table réduite : Élément courant (donc, fermé) */
                                @include fa-icon("\f13a");
                                background-color: #478fca;
                            }
                        }
                    }
                    td, th {
                        &:first-child:before {
                            @include fa-icon("\f138");
                            background-color: #686868;
                            line-height: 16px;
                            top: 10px;
                            width: 17px;
                        }
                    }
                }
            }
        }
        > tbody > tr.selected a {
            @include lien-style-bootstrap;
        }
        tbody {
            tr {
                &.selected {
                    background-color: #B0BED9 !important;
                    > th {
                        background-color: #B0BED9 !important;
                    }
                    > td {
                        background-color: #B0BED9 !important;
                    }
                }
                &.odd {
                    > .dtfc-fixed-left, > .dtfc-fixed-right {
                        background-color: $color-grey-lighter;
                    }
                }
                > .dtfc-fixed-right {
                    border-left: 1px solid $color-grey-light;
                }
            }
        }
        tr {
            &.odd {
                /* Pour utiliser select de DataTables, on doit utiliser la mécanique de DataSelect et non les options de la version Bootstrap : table-stripe, table-hover. */
                background-color: $color-grey-lighter;
            }
            &.even {
                background-color: $color-white;
            }
            &:hover {
                background-color: #f5f5f5;
            }
        }
    }
}

.table-striped>tbody>tr:nth-of-type(odd) td {
    @include print-mode {
        background-color: $color-grey-lighter !important;
    }
}

[data-type=table-liee].table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
    @include style-compatible-print((
        background-color: #dfdfdf
    ));
}

[data-type=table-liee].table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
    @include style-compatible-print((
        background-color: #e7e7e7
    ));
}

tr {
    &.entete-table-liee {
        &.shown {
            background-color: #D5D5D5 !important;
        }
    }
}

#table-evenements-filtre {
    margin-bottom: $spacing-sm;
    white-space: nowrap;
}

#table-evenements {
    margin-bottom: $spacing-sm;
    margin-top: $spacing-sm;
}

#table-template {
    padding-left: 0;
    padding-right: 0;
}

.div-table {
    /* Utilisé pour simuler une table avec des divs */
    margin: 10px;
    .div-table-entete {
        padding: 5px;
        border: 1px solid $color-grey-light;
        color: #707070;
        font-weight: 700;
        background: #F2F2F2 linear-gradient(to bottom, #F8F8F8 0, #ECECEC 100%) repeat-x scroll 0 0;
        &.separateur {
            /* pour voir les séparateurs de colonnes pleine hauteur selon la hauteur du div au lieu du texte */
            padding: 0;
            display: -webkit-flex; /* Safari */
            display: flex;
            > .div-table-colonne {
                padding: 5px;
                &:not(:last-child) {
                    border-right: 1px solid $color-grey-light;
                    /* pour voir les séparateurs de colonnes pleine hauteur selon la hauteur du div au lieu du texte */
                    -webkit-flex: auto; /* Safari 6.1+ */
                    -ms-flex: auto; /* IE 10 */
                    flex: auto;
                }
            }
        }
    }
    .div-table-contenu {
        padding: 5px;
        border: 1px solid $color-grey-light;
        border-top: none;
        &.separateur {
            padding: 0;
            > .div-table-ligne {
                /* pour voir les séparateurs de colonnes pleine hauteur selon la hauteur du div au lieu du texte */
                display: -webkit-flex; /* Safari */
                display: flex;
                > .div-table-colonne {
                    padding: 5px;
                    &:not(:last-child) {
                        border-right: 1px solid $color-grey-light;
                        /* pour voir les séparateurs de colonnes pleine hauteur selon la hauteur du div au lieu du texte */
                        -webkit-flex: auto; /* Safari 6.1+ */
                        -ms-flex: auto; /* IE 10 */
                        flex: auto;
                    }
                }
                &:not(:last-child) {
                    border-bottom: 1px solid $color-grey-light;
                }
            }
        }
    }
    div.div-table-contenu:hover {
        background-color: #f5f5f5
    }
    > .div-table-contenu {
        &:nth-child(even) {
            background-color: $color-grey-lighter;
        }
    }
}
/* Fin gestion des checkboxes pour section documents comme dans l'éditeur de fiche */

.table-methode-cout {
    thead {
        > tr {
            th {
                text-align: center;
            }
            &:first-child {
                @include style-compatible-print((
                    color: $color-white
                ));
                > th {
                    @include style-compatible-print((
                        background-color: $color-blue,
                        color: $color-white
                    ));
                }
            }
        }
    }
    tfoot {
        @include style-compatible-print((
            color: $color-white
        ));
        > tr {
            > td {
                @include style-compatible-print((
                    background-color: $color-blue
                ));
            }
        }
    }
}

#sommaire-filtres-header {
    padding: 10px;
}

.ligne-entete {
    @include style-compatible-print((
        background-color: $color-blue !important,
        background-image: none !important, /* Ace reset */
        color: $color-white !important
    ));
    th {
        @include style-compatible-print((
            background-color: $color-blue,
            color: $color-white !important
        ));
    }
}

#table-resume-valeurs-evenement {
    font-weight: bold;
}

/* tables custom styling - based on datatable and ace styling */
.dataTables_custom_container {
    overflow: auto;
}

.dataTables_custom {
    min-width: 560px;
    .dataTables_custom_head {
        color: #707070;
        font-weight: bold;
        padding: 8px;
        border: 1px solid $color-grey-light;
        background: #F2F2F2 repeat-x;
        background-image: -webkit-linear-gradient(top, #f8f8f8 0, #ececec 100%);
        background-image: -o-linear-gradient(top, #f8f8f8 0, #ececec 100%);
        background-image: linear-gradient(to bottom, #f8f8f8 0, #ececec 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff8f8f8', endColorstr='#ffececec', GradientType=0);
    }
    .dataTables_custom_subhead {
        tr {
            color: #707070;
            background: #dfdfdf;
            background-image: none;
        }
    }
}

.dataTables_double {
    .dataTables_double_container {
        display: flex;
    }
    .dataTables_double_item {
        flex: 1;
        display: flex;
        flex-direction: column;
        .dataTables_scrollBody {
            flex-grow: 1;
            table {
                min-height: 100%;
            }
            tr:last-child td {
                border-bottom: none;
            }
        }
    }
    @include breakpoint-max(lg) {
        .dataTables_double_container {
            flex-direction: column;
        }
    }
}

.datatable-columns-fixed {
    white-space: nowrap;
}

.table-scroller-custom {
    .dataTables_scroll {
        overflow: hidden;
    }
    .dataTables_scrollHead {
        overflow: visible !important;
    }
}

table.dataTable thead > tr {
    > th, > td {
        &.sorting, &.sorting_asc, &.sorting_desc, &.sorting_asc_disabled, &.sorting_desc_disabled {
            &:before, &:after {
                opacity: .75;
            }
        }
    }
}

.DTFC_NoData {
    display: none;
}

table.dataTable>tbody>tr.selected>* {
    background-color: #b0bed9;
    color: $color-black;
    box-shadow: none;
}

/* .datatable existe plus haut, mais pas le même selecteur, css est sensible à la casse */
.dataTable {
    .sorting {
        padding-right: 15px !important;
        &:before {
            @include fa-icon("\f0d8");
            font-size: 10px !important;
        }
        &:after {
            @include fa-icon("\f0d7");
            font-size: 10px !important;
        }
        &:before, &:after {
            right: 4px !important;
        }
    }
    .tooltip-inner {
        background-color: $color-black;
        white-space: initial;
    }
    .ace-thumbnail-picture {
        width: 50px;
        object-fit: cover;
    }
}

.datatables-top-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 12px;
    .datatables-topp-left, .datatables-topp-right {
        display: flex;
        align-items: center;
        > div {
            padding: 12px;
        }
    }
    .dataTables_info, .dataTables_length {
        font-size: $font-size-content-md;
        padding-top: 12px !important;
    }
    .dataTables_length {
        label {
            margin-bottom: 0 !important;
        }
    }
    @include breakpoint-max(sm) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.table-overflow-200 {
    thead, tbody {
        display: block;
        overflow-y: scroll;
        tr {
            display: table;
            width: 100%;
            table-layout: fixed;
        }
    }
    thead {
        &::-webkit-scrollbar {
            opacity: 0;
        }
    }
    tbody {
        max-height: 200px;
    }
}

.dt-striped-w-child tr.odd + tr:not(.odd):not(.even) {
    background-color: $color-grey-lighter;
}

.dt-striped-w-child tr.even + tr:not(.odd):not(.even) {
    background-color: $color-white;
}
