.daterangepicker {
    .drp-calendar {
        td {
            &.today {
                background-color: $color-primary;
                color: $color-white;
            }
        }
    }
    @media (max-height: 680px) and (max-width: 1024px) {
        transform: translateY(-50%) translateX(50%);
        &:after, &:before {
            display: none;
        }
        .calendar-table {
            td, th {
                padding: 1px 5px;
                height: 20px;
                line-height: 20px;
            }
        }
    }
}
.datepicker-container {
    position: relative;
}
