#map {
    width: 100%;
    height: 100%;
}

#map-avant {
    border-radius: .125em;
    border: 2px solid $color-blue-action-principal;
    box-shadow: 0 0 8px #999;
    width: 100%;
    height: 100%;
}

#map-apres {
    border-radius: .125em;
    border: 2px solid $color-blue-action-principal;
    box-shadow: 0 0 8px #999;
    width: 100%;
    height: 100%;
}

.switch-field {
	display: flex;
	overflow: hidden;
    border: 2px solid $color-grey-darker;
    border-radius: 4px;
    margin-bottom: $spacing-sm;
    input {
        position: absolute !important;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        border: 0;
        overflow: hidden;
    }
    label {
        color: $color-grey-darker;
        flex: 1;
        font-size: 12px;
        line-height: 1;
        text-align: center;
        padding: 10px 14px;
        transition: all 0.1s ease-in-out;
        margin-bottom: 0;
        &:hover {
            cursor: pointer;
        }
        &:not(:last-of-type) {
            border-right: 2px solid $color-grey-darker;
        }
    }
}

.switch-field input:checked + label {
    color: $color-white;
	background-color: $color-primary;
}

.leaflet-container {
    background-color: $color-white;
}

/* style custom de boutons pré-existants dans leaflet */
.leaflet-top {
    &.leaflet-left {
        padding-bottom: 30px; /* empêche la règle leaflet-control-scale-line de passer par dessus les outils */
    }
}

.leaflet-control-boxzoom {
    &.with-background-image {
        margin-top: 0;
        background-size: 16px 16px;
        background-position: center center;
        box-shadow: none;
        border: 2px solid rgba(0,0,0,0.35);
        width: 34px;
        height: 34px;
    }
}

.leaflet-styleeditor.editor-enabled{
    width: 232px;
    &.editor-large {
        width: 450px;
    }
}

.leaf-custom-disabled, .leaflet-disabled {
    pointer-events: none;
    color: $color-black;
    opacity: .6;
}

.leaflet-styleeditor-header {
    background-color: $color-secondary;
    .leaflet-styleeditor-button {
        background-color: $color-secondary;
        &:hover, &:focus, &:active, &:active:focus {
            background-color: $color-secondary-dark;
        }
    }
}

/* correction affichage informations légales layer apple - https://www.apple.com/legal/internet-services/maps/terms-fr.html */
.mk-controls-container {
    inset: 0 0 17px 0 !important;
}

.history-control {
    margin-top: 0 !important;
    &.leaflet-bar {
        a {
            width: 30px;
        }
    }
}

.center-map-control {
    margin-top: 0 !important;
}

div[id^="leaflet-control-accordion-layers"] {
    > .leaflet-control-accordion-btn {
        background: $color-white !important;
        &:hover, &:focus {
            background: $color-grey-lighter-ace !important;
            color: #585858;
        }
    }
    > label {
        font-family: 'Arial', Arial, sans-serif;
        text-shadow: none;
    }
}

.ac-container {
    article {
        label {
            cursor: auto;
        }
    }
}

.ac-container input.menu:checked + .leaflet-control-accordion-btn,
.ac-container input.menu:checked + .leaflet-control-accordion-btn:hover,
.ac-container input.menu:checked + .leaflet-control-accordion-btn:focus {
    background: $color-grey-lighter-ace !important;
    color: $color-black;
}

/* #15501 : fixe icone "?" dans la légende sigweb2 */
.leaflet-legend {
	top: -50px;
    left: -55.75px;
    display: block;
}

/* header search custom styling - used in SIG-Web 2 page */
.header-search {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-bottom: 4px;
    .header-search-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px 5px 0 !important; /* important to overwrite .btn important */
        min-height: 40px;
        .header-search-btn-txt {
            margin-left: 6px;
        }
    }
    .header-search-btn-group {
        margin: 0 5px 5px 0 !important;
        min-height: 40px;
        position: relative;
        .btn-input-container {
            height: 100%;
        }
        .select2-container {
            display: flex;
            align-items: center;
            flex-grow: 1;
            flex-shrink: 1;
            .select2-choice {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
            }
            .select2-arrow {
                b:before {
                    vertical-align: sub;
                }
            }
        }
    }
    .header-search-select {
        margin: 0 5px 5px 0;
        min-height: 40px;
        .select2-choice {
            display: flex;
            align-items: center;
            min-height: 40px;
        }
    }
    .header-search-bottom {
        display: flex;
    }
    .selected-filters-container {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        flex-shrink: 1;
    }
    @include breakpoint-max-custom(1549px) {
        .header-search-top {
            flex-grow: 1;
        }
        .selected-filters-container {
            display: none;
        }
    }
    @include breakpoint-max(lg) {
        .header-search-btn {
            min-width: 15%;
            &:empty {
                display: none;
            }
        }
        .header-search-select, .header-search-btn-group {
            flex-grow: 1;
        }
        .header-search-top, .header-search-bottom {
            display: flex;
            align-items: center;
            float: none;
            flex-wrap: wrap;
            width: 100% !important;
        }
    }
    @include breakpoint-max(sm) {
        .header-search-btn {
            .header-search-btn-txt {
                display: none;
            }
        }
        .header-search-btn-group {
            width: 100%;
        }
    }
    @include breakpoint-max-custom(400px) {
        .header-search-btn {
            min-width: 50px;
        }
    }
}


.selected-filter-count {
    @include breakpoint-min-custom(1550px) {
        display: none !important;
    }
}

.dropdown-open-top {
    top: initial;
    bottom: 100%;
}

/* map layers styling */
#exp-ue-ajouter-carte {
    position: relative;
}

.map-controls-container {
    position: relative;
    top: 0;
    right: 0;
    z-index: 1000;
    .leaflet-control-layers {
        position: absolute;
        top: 13px;
        right: 15px;
        max-width: calc(100vw - 133px);
        transition: right $time-lg ease;
        background: $color-white;
        &:hover, &:focus, &:active, &:active:focus {
            background: $color-white;
        }
    }
    .leaflet-control-layers-list {
        max-width: 100%;
        .leaflet-control-layers-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
            .btn {
                margin-bottom: 0 !important;
                font-size: $font-size-content-md;
            }
        }
        .leaflet-control-layers-selector {
            margin-top: 0;
            margin-right: 8px;
        }
        .leaflet-control-accordion-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 40px; /* prevent overlay with arrow on hover */
            min-height: 30px;
            height: auto;
            &:hover {
                &:after {
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .menu-item-checkbox, .menu-item-radio {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            label {
                flex-grow: 1;
                margin-right: 6px;
                margin-bottom: 0;
            }
        }
    }
    .bt_legend_container {
        flex-shrink: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        &:after {
            @include fa-icon('\f128');
            position: absolute;
            pointer-events: none;
            font-size: 20px;
        }
        .bt_legend {
            @include reset-button-style;
            width: 100%;
            height: 100%;
        }
    }
    .ac-container article {
        margin-top: 0;
    }
    &:not(.map-controls-container-alt) {
        .leaflet-control-layers.offset-left {
            right: 473px;
        }
    }
    @media (min-width: 992px) and (max-width: 1199px) {
        &:not(.map-controls-container-alt) {
            .leaflet-control-layers.offset-left {
                right: 403px;
            }
        }
    }
    @media (min-width: 768px) and (max-width: 991px) {
        &:not(.map-controls-container-alt) {
            .leaflet-control-layers.offset-left {
                right: 318px;
            }
        }
    }
    @include breakpoint-max(lg) {
        .leaflet-control-layers {
            right: 5px;
        }
    }
    @include breakpoint-max(sm) {
        &:not(.map-controls-container-alt) {
            .leaflet-control-layers.offset-left {
                right: 13px;
            }
        }
    }
}

.carte-exp-ue-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing-sm;
    background-color: $color-grey-light;
    .carte-exp-ue-empty-icon {
        color: $color-grey-dark;
        font-size: 130px;
    }
}

.leaflet-touch {
    .leaflet-top.leaflet-right {
        max-width: calc(100% - 64px);
    }
    .leaflet-lsidebar {
        .lineContainer1-title {
            display: inline-block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 10px;
            h3 {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        .title-btn-container {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: wrap;
            margin: 10px -7px;
            .title-btn-title {
                margin-top: 0;
                margin-bottom: 0;
            }
            .title-btn-btn {
                margin-left: 7px;
                margin-right: 7px;
            }
        }
    }
    @include breakpoint-max(lg) {
        .leaflet-lsidebar {
            .lineContainer1 {
                margin-bottom: 14px;
                .left1, .right1 {
                    float: none;
                    width: 100%;
                }
            }
        }
    }
    @include breakpoint-max(sm) {
        .leaflet-lsidebar {
            > .leaflet-control {
                padding-right: $spacing-sm;
            }
        }
    }
    @include breakpoint-max(xs) {
        .leaflet-lsidebar {
            .timeline-container:before {
                left: 0;
                width: 6px;
            }
            .timeline-label {
                margin: 0 0 18px 6px;
            }
            .timeline-info {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 3px;
                .timeline-indicator {
                    border-radius: 0;
                    width: 6px;
                    height: 100%;
                    border: none !important;
                }
            }
            .timeline-item {
                .widget-box {
                    border-left: none;
                    margin: 0 0 0 6px;
                }
            }
        }
    }
}

%lsidebar-actions-button {
    background-color: $color-white;
    border: 1px solid $color-grey-blue;
    width: 36px;
    height: 36px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color $time-md ease;
    .lsidebar-actions-icon {
        color: $color-grey-dark;
        font-size: 18px;
    }
    &:hover, &:focus {
        background-color: $color-grey-lighter;
    }
    &:active, &:focus {
        background-color: $color-grey-light;
    }
}

.lsidebar-actions-button {
    @extend %lsidebar-actions-button;
}

.leaflet-lsidebar {
    .lsidebar-actions-in {
        position: absolute;
        z-index: 1001;
        top: 20px;
        right: 89px;
        display: flex;
        align-items: center;
        .lsidebar-actions-button {
            margin-right: 7px;
        }
    }

    .lsidebar-actions-out {
        position: absolute;
        left: -49px;
        top: 49px;
        pointer-events: none;
        opacity: 0;
        transition: opacity $time-md ease;
        .lsidebar-actions-button {
            border: none;
            box-shadow: 0 1px 5px rgba(0,0,0,0.4);
            margin-top: 10px;
            .lsidebar-actions-icon {
                font-size: 22px;
            }
        }
    }

    &.minimized:not(.visible) {
        .lsidebar-actions-out {
            left: -91px;
            opacity: 1;
            pointer-events: initial;
        }
    }

    .closeSb {
        @extend %lsidebar-actions-button;
        right: 53px;
        color: $color-white;
        &:after {
            @include fa-icon('\f00d');
            color: $color-grey-dark;
            font-size: 18px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
        }
    }
    @include breakpoint-max(lg) {
        .lsidebar-actions-in {
            right: 82px;
        }
        &.minimized:not(.visible) {
            .lsidebar-actions-out {
                left: -150px;
            }
        }
        .closeSb {
            right: 46px;
        }
    }
    @include breakpoint-max(md) {
        &.minimized:not(.visible) {
            .lsidebar-actions-out {
                left: -236px;
            }
        }
    }
    @include breakpoint-max(sm) {
        .lsidebar-actions-in {
            right: 62px;
        }
        &.minimized:not(.visible) {
            .lsidebar-actions-out {
                left: -41px;
            }
        }
        .closeSb {
            right: 25px;
        }
        &.right {
            &.visible {
                background-color: $color-white;
                z-index: 1200;
            }
        }
    }
}

.leaflet-popup-header {
    padding-bottom: 10px;
    margin-bottom: $spacing-sm;
    border-bottom: 1px solid #c3c3c3;
    .leaflet-popup-header-text {
        margin: 0;
        font-size: $font-size-content-md;
        font-weight: bold;
    }
}

.leaflet-popup-item {
    &:not(:last-child) {
        margin-bottom: $spacing-sm;
    }
    .leaflet-popup-item-text {
        margin: 0;
    }
}

.leaflet-popup-item-w-button {
    display: flex;
    align-items: center;
    .leaflet-popup-item-text {
        flex-grow: 1;
        flex-shrink: 1;
    }
    .leaflet-popup-item-button {
        flex-grow: 0;
        flex-shrink: 0;
        margin-left: 6px;
    }
}

.leaflet-popup-subitem {
    display: flex;
    margin: -6px;
    .leaflet-popup-subitem-text {
        flex-grow: 1;
        padding: 6px;
    }
}

.leaflet-draw-collapsable {
    margin-top: 0 !important;
    overflow: hidden;
    height: 0;
    transition: height $time-md ease;
    @include breakpoint-min-custom(885px) {
        margin-top: 10px !important;
        height: auto !important;
        overflow: initial !important;
    }
}

.leaflet-draw {
    .leaflet-draw-section {
        .leaflet-draw-toolbar {
            margin: 0;
            float: none;
        }
    }
    a {
        .sr-only {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
        }
    }
}

.leaflet-retina .leaflet-draw-toolbar a {
    background-image: linear-gradient(transparent, transparent), url('https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/images/spritesheet.svg');
}

.leaflet-draw-toolbar {
    margin: 0;
    float: none;
    a {
        background-image: linear-gradient(transparent, transparent), url('https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/images/spritesheet.svg');
        background-size: 300px 30px;
        background-clip: padding-box;
    }
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-markertext,
.leaflet-draw-toolbar .leaflet-draw-draw-markertext {
    background-image: url($url-img-general + "text.png");
	background-size: 15px 15px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
	background-position: -273px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
	background-position: -271px -1px;
}

.leaflet-control-collapse {
    @include breakpoint-min-custom(885px) {
        display: none;
    }
}

.leaflet-marker-pane{
    .leaflet-measure-path-measurement {
        font-size: 16px;
    }
}

.leaflet-edit-marker-selected {
	background-color: rgba(254, 87, 161, 0.1);
	border: 4px dashed rgba(254, 87, 161, 0.6);
	-webkit-border-radius: 4px;
	border-radius: 4px;
	box-sizing: content-box;
}
